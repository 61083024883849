import { useState } from "react";
import TextField from '@mui/material/TextField';


export function ConnectWithUs(){
    return(
        <div className="flex flex-col justify-center items-center w-full h-full">
            <div className="flex justify-center items-center w-full h-full gap-[5.6vw] mt-[6.9vw] mx-[2.8vw]">
                <div className="flex flex-col justify-start items-start w-[33.2vw] h-[60vw] gap-[1.7vw]">
                    <div>CONNECT WITH US</div>
                    <div>Let’s start a conversation – we’re here to bring your ideas to life, and we can’t wait to work with you!</div>
                </div>
                <form className="flex flex-col justify-start my-[5.6vw] ">
                    <div className="w-[51.5vw] h-[58.5vw] text-size-[1.7vw] pt-[5.56]">
                        <TextField
                            id="name-surname"
                            required
                            label="Name Surname"
                            type="text"
                            variant="standard"
                            slotProps={{
                                inputLabel: {
                                shrink: true,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true, 
                                sx: {
                                "&:focus-within": {
                                    outline: "none", 
                                },
                                },
                            }}
                            sx={{
                                "& .MuiInputLabel-root": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputBase-root": {
                                borderBottom: "1px solid black", 
                                transition: "none", 
                                height: "5.56vw", 
                                marginBottom:"3.4vw"
                                },
                                "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black", 
                                },
                                "& .MuiInputBase-input": {
                                padding: 0, 
                                height: "100%", 
                                },
                            }}
                            className="w-full"
                        />
                        <TextField
                            id="email"
                            required
                            label="Email"
                            type="text"
                            variant="standard"
                            slotProps={{
                                inputLabel: {
                                shrink: true,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true, 
                                sx: {
                                "&:focus-within": {
                                    outline: "none", 
                                },
                                },
                            }}
                            sx={{
                                "& .MuiInputLabel-root": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputBase-root": {
                                borderBottom: "1px solid black", 
                                transition: "none", 
                                height: "5.56vw", 
                                marginBottom:"3.4vw"
                                },
                                "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black", 
                                },
                                "& .MuiInputBase-input": {
                                padding: 0, 
                                height: "100%", 
                                },
                            }}
                            className="w-full"
                        />
                        <TextField
                            id="phoneNumber"
                            required
                            label="Phone Number"
                            type="number"
                            variant="standard"
                            slotProps={{
                                inputLabel: {
                                shrink: true,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true, 
                                sx: {
                                "&:focus-within": {
                                    outline: "none", 
                                },
                                },
                            }}
                            sx={{
                                "& .MuiInputLabel-root": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputBase-root": {
                                borderBottom: "1px solid black", 
                                transition: "none", 
                                height: "5.56vw", 
                                marginBottom:"3.4vw"
                                },
                                "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black", 
                                },
                                "& .MuiInputBase-input": {
                                padding: 0, 
                                height: "100%", 
                                },
                            }}
                            className="w-full"
                        />
                        <div className="flex gap-[1.7vw] mb-[3.4vw]">
                            <div className="flex-1">
                                <TextField
                                    id="city"
                                    required
                                    label="City"
                                    type="text"
                                    variant="standard"
                                    slotProps={{
                                        inputLabel: {
                                        shrink: true,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true, 
                                        sx: {
                                        "&:focus-within": {
                                            outline: "none", 
                                        },
                                        },
                                    }}
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                        color: "rgb(209 213 219)",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                        color: "rgb(209 213 219)",
                                        },
                                        "& .MuiInputBase-root": {
                                        borderBottom: "1px solid black", 
                                        transition: "none", 
                                        height: "5.56vw", 
                                        marginBottom:"3.4vw"
                                        },
                                        "& .MuiInputBase-root:focus-within": {
                                        borderBottom: "1px solid black", 
                                        },
                                        "& .MuiInputBase-input": {
                                        padding: 0, 
                                        height: "100%", 
                                        },
                                    }}
                                    className="w-full"
                                />
                            </div>
                            <div className="flex-1">
                                <TextField
                                    id="state"
                                    required
                                    label="State"
                                    type="text"
                                    variant="standard"
                                    slotProps={{
                                        inputLabel: {
                                        shrink: true,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true, 
                                        sx: {
                                        "&:focus-within": {
                                            outline: "none", 
                                        },
                                        },
                                    }}
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                        color: "rgb(209 213 219)",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                        color: "rgb(209 213 219)",
                                        },
                                        "& .MuiInputBase-root": {
                                        borderBottom: "1px solid black", 
                                        transition: "none", 
                                        height: "5.56vw", 
                                        marginBottom:"3.4vw"
                                        },
                                        "& .MuiInputBase-root:focus-within": {
                                        borderBottom: "1px solid black", 
                                        },
                                        "& .MuiInputBase-input": {
                                        padding: 0, 
                                        height: "100%", 
                                        },
                                    }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <TextField
                            id="idea"
                            required
                            label="Tell us about your idea"
                            type="text"
                            variant="standard"
                            slotProps={{
                                inputLabel: {
                                shrink: true,
                                },
                            }}
                            InputProps={{
                                disableUnderline: true, 
                                sx: {
                                "&:focus-within": {
                                    outline: "none", 
                                },
                                },
                            }}
                            sx={{
                                "& .MuiInputLabel-root": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                color: "rgb(209 213 219)",
                                },
                                "& .MuiInputBase-root": {
                                borderBottom: "1px solid black", 
                                transition: "none", 
                                height: "5.56vw", 
                                marginBottom:"3.4vw"
                                },
                                "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black", 
                                },
                                "& .MuiInputBase-input": {
                                padding: 0, 
                                height: "100%", 
                                },
                            }}
                            className="w-full"
                        />
                    </div>
                    <button className="justify-start item-start w-[17.37vw] h-[5.07vw] bg-green-900 text-white">Send Message</button>
                </form>
            </div>
        </div>
    )
}