import { TextField } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function Footer(){

  const featuredMenus = [
    { "name": "Art Building", "link": "/home" },
    { "name": "Art Interior", "link": "/about" },
    { "name": "Real Estate", "link": "/contact" }
  ];

  const companyMenus = [
    { "name": "About", "link": "/home" },
    { "name": "Gallery", "link": "/about" },
    { "name": "Carrers", "link": "/contact" },
    { "name": "Contact Us", "link": "/contact" }
  ];

  const socialsMenus = [
    { "name": "X", "link": "/home" },
    { "name": "Youtube", "link": "/about" },
    { "name": "Linkedin", "link": "/contact" },
    { "name": "Instagram", "link": "/contact" }
  ];

  return(
   <div className="max-w-[100vw] h-full flex flex-col justify-center items-center px-[5.6vw]">
      <div className="mt-[7vw] w-full h-full">
        Newsletter
      </div>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[50%] h-full flex flex-col justify-center items-start mt-[2.8vw] gap-[5.6vw]">
          <div className="w-full h-full flex justify-start items-center gap-[0.7vw]">
            <TextField id="input-with-sx" label="Email" variant="standard" className="w-[16.8vw]" InputProps={{disableUnderline: true,}} sx={{"& .MuiInputLabel-root": {color: "black",},"& .MuiInputLabel-root.Mui-focused": {color: "black",},  "& .MuiInputBase-root": {
              borderBottom: "1px solid black",
              transition: "none", 
              height: "3.8vw",
              },
              "& .MuiInputBase-root:focus-within": {
              borderBottom: "1px solid black",
              },
              "& .MuiInputBase-input": {
              padding: 0,
              height: "100%",
              },}} 
            />
            <div className="">
              Subscribe
            </div>
          </div>
          <div className="w-full h-full flex justify-start items-start gap-[8.9vw]">
            <FooterMenus menus={featuredMenus} title="Featured"/> 
            <FooterMenus menus={companyMenus} title="Company"/>
            <FooterMenus menus={socialsMenus} title="Socials"/>
          </div>
        </div>
        <div className="w-[50%] h-full flex flex-col justify-center items-start mt-[2.8vw] gap-[5.6vw]">
        <div className="w-full h-full flex justify-start items-center gap-[2.8vw]">
            <div className="w-[11.8vw] h-[3.8vw] flex justify-center items-center bg-darkGreen text-white">
              Catalogue
            </div>
            <div className="w-[15.7vw] h-[3.8vw] flex justify-center items-center border-[1px] border-black">
              Company Profile
            </div>
          </div>
          <div className="w-full h-full flex flex-col justify-start items-start gap-[2.8vw]">
              <div>
                Contact
              </div>
              <div className="w-full h-full flex items-center gap-[2.8vw]">
                <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.1vw]">
                  <div className="">
                    Whatsapp
                  </div>
                  <div>
                    +(62) 813 8337 8582
                  </div>
                </div>
                <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.1vw]">
                  <div>
                    Email
                  </div>
                  <div>
                    info@opatan.id
                  </div>
                </div>
              </div>
              
              <div className="w-full h-full flex flex-col justify-center items-start gap-[1.1vw]">
                <div>
                  Address
                </div>
                <div>
                  Jl. Subak Belaki No. 60, Sukawati, Gianyar, Bali 80582
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="w-[89vw] h-full pb-[3.1vw] border-b-[1px] border-black mt-[5.6vw]">
        <div className="w-[89vw] h-[16.8vw] flex justify-center items-center ">
              OPATAN
        </div>
      </div>
      <div className="pt-[2.9vw] w-full h-[8vw] flex justify-center items-center ">
        <div className="w-[93vw] h-[7.3vw] flex justify-between items-center">
          <div>
            2024 Opatan, All Rights Reserved.
          </div>
          <div>
            |
          </div>
          <div>
            Terms of Service
          </div>
          <div>
            |
          </div>
          <div>
            Privacy Policy
          </div>
        </div>
        <div className="w-full h-full justify-end items-center flex gap-[0.7vw]">
          <div>
            Back To Top
          </div>
          <ArrowUpwardIcon/>
        </div>
      </div>
   </div>
  )
}

function FooterMenus({title, menus}){
  return(
    <div className="w-fit h-full flex flex-col justify-center items-start gap-[2.8vw]">
      <div>{title}</div>
      <div className="flex flex-col justify-center items-start gap-[1.4vw]">
        {
          menus.map((menu, id) => {
            return(
              <div id={id}>
                {menu.name}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}