import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Test({ data }) {
  function Description({ video }) {

    const { scrollYProgress } = useScroll();
    const [show, setShow] = useState(false)
    const [direction, setDirection] = useState(0)
    const [lastPosition, setLastPosition] = useState('')


    const { inView, ref } = useInView({
      triggerOnce: false, // Trigger more than once
      threshold: 1, // Element needs to be 50% visible to trigger inView
    });

    const translateXLeft = useTransform(
      scrollYProgress,
      [0.1, 0.21],
      ["-34.9vw", "0vw"]
    );
    const translateXRight = useTransform(
      scrollYProgress,
      [0.1, 0.21],
      ["34.9vw", "0vw"]
    );

    useEffect(() => {
      const unsubscribe = translateXLeft.on("change", (value) => {
        setLastPosition(value)
      });

      return () => unsubscribe();  // Cleanup listener saat komponen unmount
    }, [translateXLeft]);

    useEffect(() => {

      let timer = setTimeout(() => setShow(true), 500);
      const _onScroll = e => {
        if (!show) return
        setShow(false)
        if (e.deltaY < 0) {
          setDirection(0)
        }
        if (e.deltaY > 0) {
          setDirection(1)
        }
      }
      window.addEventListener("wheel", _onScroll);

      return () => {
        clearTimeout(timer)
        window.removeEventListener("wheel", _onScroll)
      };
    }, [direction, show]);


    return (
      <div
        className="max-w-[100vw] h-full flex flex-col justify-center items-center gap-[16.6vw] pb-[16vw] bg-white relative z-20"
      >
        <div className="w-full h-full flex flex-col justify-center items-start text-[8.39vw] text-darkGreen ml-[2.2vw] ">
          <motion.div style={{ x: translateXLeft }} className="text-[8.39vw]">
            YOUR VILLA
          </motion.div>

          <motion.div className="text-[8.39vw] relative">
            <>
              {direction == 1 && lastPosition == "0vw" ? (
                <motion.div
                  className="absolute bg-red-200 z-20"
                  initial={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                  animate={{ width: "99.1vw", height: "60vw", top: "-20vw", left: "-1.1vw" }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  <video
                    src={video.image_slider}
                    className="w-full h-full object-cover"
                    autoPlay
                    muted
                  ></video>
                </motion.div>
              ) : direction == 0 && lastPosition !== "0vw" ? (
                <motion.div
                  className="bg-red-200 absolute z-20"
                  initial={{ width: "99.1vw", height: "60vw", top: "-20vw", left: "-1.1vw" }}
                  animate={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  <video
                    src={video.image_slider}
                    className="w-full h-full object-cover absolute"
                    autoPlay
                    muted
                  ></video>
                </motion.div>
              ) : (
                <motion.div
                  className="bg-red-200 absolute z-20"
                  initial={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                  animate={{ width: "29.3vw", height: "11.3vw", top: "0vw", left: "0vw" }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  <video
                    src={video.image_slider}
                    className="w-full h-full object-cover absolute"
                    autoPlay
                    muted
                  ></video>
                </motion.div>
              )}
            </>
            <div className="ml-[32.3vw]">REIMAGINED AS</div>
          </motion.div>
          <motion.div style={{ x: translateXRight }} className="text-[8.39vw]">
            A WORK OF ART
          </motion.div>
        </div>
      </div>
    );
  }
}

