import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ArtPage from "./pages/Art/Art";
import ContactPage from "./pages/Contact/Contact";
import ArtDetailPage from "./pages/ArtDetails/ArtDetails";
import Test from "./pages/ArtDetails/test";
import AboutUsPage from "./pages/AboutUs/AboutUs";
import FAQPage from "./pages/FAQ/FAQ";
import RealEstatePage from "./pages/RealEstate/RealEstate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/art/:type" element={<ArtPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/real-estate" element={<RealEstatePage />} />
          <Route path="/detail/:id" element={<ArtDetailPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/test" element={<Test />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
