import Navbar from "../components/NavbarComponents";
import { Swiper, SwiperSlide } from "swiper/react";
import howDoWeWork from "../assets/howDoWeWork.jpeg";
import arrowLeft from "../assets/arrowLeft.png"
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { motion, useScroll, useTransform } from "framer-motion";
import Marquee from "react-fast-marquee";
import {
  ArtDetail,
  GetInTouch,
  GetInTouchHome,
} from "../components/ArtComponents";
import Footer from "../components/FooterComponents";
import { apiHandler, apiImageFilesHandler, apiImageHandler } from "../utilities/apiHandler";
import { scroll } from "motion";
import arrow from "../assets/arrow.png";
import arrowTopRightWhite from "../assets/arrowTopRightWhite.png";
import arrowTopRightBlack from "../assets/arrowTopRightBlack.png";
import arrowRightWhite from "../assets/arrowRightWhite.png";
import building from "../assets/building.jpeg";
import { useInView } from "react-intersection-observer";
import { Inbox } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [artDetailData, setArtDetailData] = useState([]);
  const [howDoWeWorkData, setHowDoWeWork] = useState([]);
  const [animation, setAnimation] = useState(true);
  const [videoData, setVideoData] = useState({});
  const [ourCollectionTopData, setOurCollectionTopData] = useState([]);
  const [ourCollectionBottomData, setOurCollectionBottomData] = useState([]);
  const [whoIsOpatanMadeForData, setWhoisOpatanMadeForData] = useState([]);

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const splitArray = (array) => {
    const middle = Math.ceil(array.length / 2); // Cari titik tengah
    const firstHalf = array.slice(0, middle);
    const secondHalf = array.slice(middle);

    return [firstHalf, secondHalf];
  };


  useEffect(() => {
    // window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("opatan_do");
        headerData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const howDoWeWorkData = await apiHandler("how_do_we_work");
        howDoWeWorkData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const videoData = await apiHandler("trusted_video");
        videoData[0].image_slider = await apiImageHandler(
          videoData[0].image_slider
        );

        const realEstateSlider = await apiHandler("real_estate_lists")
        realEstateSlider.map((item) => {
          item.type = "real_estate"
          return item
        })
        const artInteriorSlider = await apiHandler("art_interior_lists")
        artInteriorSlider.map((item) => {
          item.type = "interior"
          return item
        })
        const artBuildingSlider = await apiHandler("art_building_lists")
        artBuildingSlider.map((item) => {
          item.type = "building"
          return item
        })

        const collections = shuffleArray([...realEstateSlider, ...artInteriorSlider, ...artBuildingSlider])
        collections.map(async (item) => {
          item.image = await apiImageFilesHandler(item.type, item.gallery);
          return item;
        });

        const [ourCollectionTopData, ourCollectionBottomData] = splitArray(collections)
        console.log(ourCollectionBottomData)

        const whoIsOpatanMadeForData = await apiHandler(
          "who_is_opatan_made_for"
        );
        whoIsOpatanMadeForData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        setWhoisOpatanMadeForData(whoIsOpatanMadeForData);
        setVideoData(videoData[0]);
        setOurCollectionBottomData(ourCollectionBottomData);
        setOurCollectionTopData(ourCollectionTopData);
        setHowDoWeWork(howDoWeWorkData);
        setArtDetailData(headerData);

        const timer = setTimeout(() => {
          setAnimation(false);
        }, 5000);

        return () => clearTimeout(timer);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full relative z-20">
      <Preloader />
      <Navbar type="default" />
      <HomeCard />
      <TrustedBy />
      <Description video={videoData} />
      <ArtDetail data={artDetailData} />
      <OurCollections
        dataTop={ourCollectionTopData}
        dataBottom={ourCollectionBottomData}
      />
      <WhoIsOpatanMadeFor data={whoIsOpatanMadeForData} />
      <HowDoWeWorkSection data={howDoWeWorkData} />
      <GetInTouch />
      <Footer />
    </div>
  );
}

export function Preloader() {
  const [isAnimating, setIsAnimating] = useState(true); // State untuk memantau animasi

  // Menambahkan dan menghapus overflow: hidden pada body saat animasi dimulai dan selesai

  if (isAnimating) {
    return (
      <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-40 flex justify-center items-center bg-white">
        {/* Bg White dengan animasi opacity */}
        <motion.div
          className="w-[100vw] h-[100vh] absolute top-0 left-0 z-10 overflow-hidden flex justify-center items-center"
          initial={{ opacity: 1 }} // Awal opacity 1
          animate={{
            opacity: [1, 1, 0.5, 0.5, 0], // Tetap 1 selama detik 1-3, lalu jadi 0 pada detik 4
          }}
          transition={{
            duration: 2.3, // Durasi total animasi 4 detik
            times: [0, 0.75, 1, 1, 1], // 1-3 detik opacity 1, 4 detik opacity 0
            ease: "easeInOut", // Jenis easing
            // Mengubah status animasi setelah selesai
          }}
        ></motion.div>

        {/* Animasi pergerakan untuk bg-red-300 */}
        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20" // z-20 agar tetap di atas
          initial={{ top: "100vh", opacity: 1 }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"], opacity: 1 }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 0,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 0.5,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 1,
          }}
        ></motion.div>

        <motion.div
          className="w-[25vw] h-full bg-darkGreen relative z-20"
          initial={{ top: "100vh" }}
          animate={{ top: ["100vh", "0vh", "0vh", "-100vh"] }}
          transition={{
            duration: 3,
            times: [0, 0.3, 0.7, 1],
            ease: "easeInOut",
            delay: 1.5,
            onComplete: () => setIsAnimating(false),
          }}
        ></motion.div>
      </div>
    );
  }
}

function WhoIsOpatanMadeFor({ data }) {
  const [index, setIndex] = useState(1);
  const swiperRef = useRef(null);

  return (
    <div className="w-full h-full flex justify-center items-center pt-[5.5vw] flex-col bg-white relative z-20">
      <div className="w-full h-full justify-between items-start flex mb-[2.7vw] px-[5.5vw]">
        <div className="text-md">WHO IS OPATAN MADE FOR</div>
        <div className="flex justify-center items-center gap-[5.8vw]">
          {
            index > 1 && <img
              src={arrowLeft}
              className="w-[1.6vw] h-[1.11vw]"
              id="swiper-next"
              onClick={() => {
                const swiper = swiperRef.current;
                if (swiper && index > 0) {
                  swiper.slidePrev(); // Pindah ke slide sebelumnya
                }
              }}
              disabled={index >= data.length}
            ></img>
          }
          <img
            src={arrow}
            className="w-[1.6vw] h-[1.11vw]"
            id="swiper-next"
            onClick={() => {
              const swiper = swiperRef.current;
              if (swiper && index < data.length) {
                swiper.slideNext(); // Navigasi ke slide berikutnya
              }
            }}
            disabled={index >= data.length}
          ></img>
          <div className="text-md3">
            {index}/{data.length}
          </div>
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={false} // Nonaktifkan navigasi default Swiper
        modules={[Navigation]}
        spaceBetween={window.innerWidth * 0.016}
        slidesOffsetBefore={0}
        slidesPerView={2.44}
        slidesPerGroup={1}
        initialSlide={0}
        onSlideChange={(swiper) => {
          // Update index, tetapi batasi ke jumlah data
          setIndex(Math.min(swiper.activeIndex + 1, data.length));
        }}
        className="w-full !h-[47.6vw] flex !ml-[5.5vw] "
      >
        {data.map((item, idx) => (
          <SwiperSlide
            key={idx}
            className="basis-[40%] w-[40.2vw] h-[37.7vw] flex justify-center items-center transition-all duration-300 ease-in-out relative group"
          >
            <div className="w-full h-[37.7vw] group-hover:h-[47.6vw] transition-all duration-500 ease-in-out justify-start relative">
              <div className="w-full h-full relative z-0">
                <img
                  src={item.image}
                  alt={`Art ${idx + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-fit h-fit top-[32.3vw] absolute ml-[1.6vw] text-md flex gap-[1.3vw] justify-start items-center text-white">
                <div>{item.title}</div>
                <img src={arrowRightWhite} className="w-[1.6vw] h-[1.5vw]" />
              </div>
              {/* hover effect */}
              <div className="w-full h-[23.8vw] absolute bottom-0 z-10 bg-darkGreen2 text-white px-[1.6vw] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                <div className="w-full flex flex-col justify-center items-start mt-[5.9vw] gap-[1.3vw]">
                  <div className="w-full h-full flex justify-start items-center gap-[1.9vw]">
                    <div className="text-md3">{item.title}</div>
                    <img
                      src={arrowTopRightWhite}
                      className="w-[1.6vw] h-[1.5vw]"
                    />
                  </div>
                  <div className="text-sm">{item.detail}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Tambahkan slide bayangan */}
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
        <SwiperSlide className="basis-[40%] w-[40.2vw]"></SwiperSlide>
      </Swiper>
    </div>
  );
}

function Video({ data }) {
  return (
    <div className="w-full h-[71.6vw]">
      {data.image_slider ? (
        <video
          src={data.image_slider}
          className="w-full h-full"
          autoPlay
          muted
        ></video>
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-500">
          <p></p>
        </div>
      )}
    </div>
  );
}

function OurCollections({ dataTop, dataBottom }) {

  const navigate = useNavigate()

  return (
    <div className="w-full h-full flex flex-col justify-center items-center pt-[11.1vw] bg-white relative z-20">
      <div className="w-full h-[35.8vw]">
        <Marquee className="w-full h-[35.8vw] flex">
          {dataTop.map((item, idx) =>
            idx % 2 == 0 ? (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[3.5vw] flex items-start "
              >
                <div className="relative group cursor-pointer" onClick={() => navigate(`/detail/${item.type}/${item.id}`)}>
                  <img
                    src={item.image[0]}
                    className="max-w-[32vw] max-h-[35.8vw] object-cover "
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={idx}
                  className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-end "
              >
                  <div className="relative group cursor-pointer" onClick={() => navigate(`/detail/${item.type}/${item.id}`)}>
                  <img
                      src={item.image[0]}
                    className="max-w-[32vw] max-h-[35.8vw] object-cover "
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            )
          )}
        </Marquee>
      </div>
      <div className="text-xxxl w-full h-full mt-[5.8vw] mb-[3.49vw] ml-[5.5vw]">
        Our Collections
      </div>
      <div className="w-full h-[35.8vw]">
        <Marquee className="w-full h-[35.8vw] flex" direction="right">
          {dataBottom.map((item, idx) =>
            idx % 2 == 0 ? (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-start"
              >
                <div className="relative group cursor-pointer" onClick={() => navigate(`/detail/${item.type}/${item.id}`)}>
                  <img
                    src={item.image[0]}
                    className="max-w-[32vw] max-h-[35.8vw] object-cover"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={idx}
                className="w-[32vw] h-[35.8vw] mx-[5vw] flex items-end"
              >
                  <div className="relative group cursor-pointer" onClick={() => navigate(`/detail/${item.type}/${item.id}`)}>
                  <img
                      src={item.image[0]}
                    className="max-w-[32vw] max-h-[35.8vw] object-cover"
                    alt="image"
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-lightGray bg-opacity-50 flex justify-center items-center text-[3.3vw] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {item.title}
                  </div>
                </div>
              </div>
            )
          )}
        </Marquee>
      </div>
    </div>
  );
}

function Description({ video }) {
  const { scrollYProgress } = useScroll();
  const [left, setLeft] = useState(2.2);
  const [top, setTop] = useState(12.5);
  const [size, setSize] = useState({ width: 29.3, height: 11.3 });
  const [isLock, setIsLock] = useState(false);

  const { inView, ref } = useInView({
    triggerOnce: false,
    threshold: 0.9,
  });

  const translateXLeft = useTransform(
    scrollYProgress,
    [0.1, 0.21],
    ["-34.9vw", "0vw"]
  );
  const translateXRight = useTransform(
    scrollYProgress,
    [0.1, 0.21],
    ["34.9vw", "0vw"]
  );

  // Efek untuk pergerakan horizontal
  useEffect(() => {
    let isLocked = false;
    let targetScrollY = 0;

    const handleWheel = (e) => {
      if (isLocked) {
        e.preventDefault();
      }
    };

    const handleTouchMove = (e) => {
      if (isLocked) {
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      const keys = [32, 33, 34, 35, 36, 38, 40]; // Space, PageUp, PageDown, End, Home, Up, Down
      if (isLocked && keys.includes(e.keyCode)) {
        e.preventDefault();
      }
    };

    const lockScroll = (targetY) => {
      if (isLocked) return;
      setIsLock(true);
      targetScrollY = targetY;

      window.scrollTo(0, targetScrollY);
      document.body.style.overflow = "hidden"; // Matikan overflow agar smooth
      window.addEventListener("wheel", handleWheel, { passive: false });
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("keydown", handleKeyDown, { passive: false });
    };

    const unlockScroll = () => {
      setIsLock(false);
      document.body.style.overflow = ""; // Balikin overflow agar bisa scroll lagi
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("keydown", handleKeyDown);
    };

    const unsubscribe = translateXLeft.on("change", (value) => {
      const currentScrollY = window.scrollY;
      if (!isLocked && inView && translateXLeft.get() == "0vw") {
        lockScroll(currentScrollY);
      }
    });

    if (size.width === 100) {
      unlockScroll(); // Unlock scroll pas udah full size
    } else if (size.width > 29.3 && size.width < 100 && inView) {
      lockScroll(window.scrollY); // **Tetap lock selama proses mengecil/membesar**
    } else if (size.width === 29.3) {
      unlockScroll(); // **Baru unlock setelah video mengecil sempurna**
    }

    return () => {
      unsubscribe();
    };
  }, [translateXLeft, inView, size.width]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (scrollYProgress.get() >= 0.21) {
        setSize((prevSize) => {
          let newWidth = prevSize.width;
          let newHeight = prevSize.height;
          if (e.deltaY > 0 && prevSize.width < 100) {
            newWidth = Math.min(prevSize.width + 5, 100); // Max 100vw
            newHeight = Math.min(prevSize.height + 5, 70); // Max 100vh
          } else if (e.deltaY < 0 && prevSize.width > 29.3) {
            newWidth = Math.max(prevSize.width - 5, 29.3); // Min 29.3vw
            newHeight = Math.max(prevSize.height - 5, 11.3); // Min 11.3vh
          }

          if (newWidth == 100 && newHeight == 70) {
            setIsLock(false);
          }

          // **Hitung left & top berdasarkan scroll arah**
          let progress = (newWidth - 29.3) / (100 - 29.3); // 0 -> 1
          let newLeft = 2.2 - progress * 2.2;
          let newTop = 12.5 - progress * (12.5 - -18); // Dari 12.5vw ke 0vw

          setLeft(newLeft);
          setTop(newTop);
          return { width: newWidth, height: newHeight };
        });
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [size, scrollYProgress]);

  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center gap-[16.6vw] pb-[16vw] bg-white relative z-20">
      <div
        className="w-full h-full flex flex-col justify-center items-start text-[8.39vw] text-darkGreen ml-[2.2vw]"
        ref={ref}
      >
        <motion.div style={{ x: translateXLeft }} className="text-[8.39vw]">
          YOUR VILLA
        </motion.div>
        <motion.div
          className="bg-red-200  z-20 absolute"
          style={{
            left: `${left}vw`,
            width: `${size.width}vw`,
            height: `${size.height}vw`,
            top: `${top}vw`,
          }} // Scaling berdasarkan state
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <video
            src={video.image_slider}
            className="w-full h-full object-cover "
            autoPlay
            muted
          ></video>
        </motion.div>
        <div className="ml-[32.3vw] relative h-[11.3vw]">REIMAGINED AS</div>
        <motion.div style={{ x: translateXRight }} className="text-[8.39vw]">
          A WORK OF ART
        </motion.div>
      </div>
    </div>
  );
}

function HowDoWeWorkSection({ data }) {
  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center p-[5.5vw] gap-[5.5vw] bg-white z-20 relative">
      <div className="w-full h-full flex justify-start items-center sticky top-[8vw]">
        <div className="text-[1.6vw]">HOW DO WE WORK</div>
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center relative">
        {data.map((data, index) => (
          <HowDoWeWorkCard data={data} key={index} index={index} />
        ))}
      </div>
    </div>
  );
}

function HowDoWeWorkCard({ data, index }) {
  return (
    <div
      className="w-full h-full sticky "
      style={{
        top: `calc(10vw + ${index} * 1vw)`,
        paddingTop: `${index * 7}vw`, // tambahin margin biar gak nabrak
      }}
    >
      <div className="w-full h-full flex justify-between items-start gap-[2.7vw] py-[2.7vw] border-lightGray border-b-[1px] bg-white">
        <div className="w-[34.9vw] h-[25vw] ">
          <img src={data.image} className="w-full h-full object-cover"></img>
        </div>
        <div className="w-[51.7vw] h-full flex flex-col justify-center items-start gap-[2.7vw] ">
          <div className="w-full h-full flex flex-col justify-center items-start font-light">
            <div className="text-[1.1vw]">Step {data.id}</div>
            <div className="text-[2.7vw]">{data.title}</div>
          </div>
          <div className="w-full h-full flex flex-col justify-center items-start gap-[1.6vw]">
            <div className="text-[1.3vw]">{data.subtitle}</div>
            <div className="text-[1.1vw] font-light">{data.detail}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HomeCard() {
  const [headerData, setHeaderData] = useState({});
  const [sliderData, setSliderData] = useState([]);
  const { scrollYProgress } = useScroll();
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState(0);
  const [lastPosition, setLastPosition] = useState(0);
  const [hasView, setHasView] = useState(false);

  const { inView, ref } = useInView({
    triggerOnce: false, // Trigger more than once
    threshold: 0.89, // Element needs to be 50% visible to trigger inView
  });

  useEffect(() => {
    let isLocked = false;
    let targetScrollY = 0;

    const handleWheel = (e) => {
      if (isLocked) {
        e.preventDefault();
        window.scrollTo(0, targetScrollY); // Paksa tetap di posisi target
      }
    };

    const handleScroll = () => {
      if (isLocked) {
        window.scrollTo(0, targetScrollY); // Paksa tetap saat scroll event
      }
    };

    const lockScroll = (targetY) => {
      if (isLocked) return;
      isLocked = true;
      targetScrollY = targetY;

      window.scrollTo(0, targetScrollY); // Posisikan langsung tanpa smooth
      document.body.style.overflow = "hidden"; // Nonaktifkan scroll bawaan
      window.addEventListener("wheel", handleWheel, { passive: false });
      window.addEventListener("scroll", handleScroll);

      // console.log("Scroll Locked at", targetY);
    };

    const unlockScroll = (currentScrollY) => {
      isLocked = false;

      document.body.style.overflow = ""; // Aktifkan scroll lagi
      window.scrollTo({
        top: currentScrollY + 150,
        behavior: "smooth", // Tambahkan efek smooth
      });
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("scroll", handleScroll);
      // console.log("Scroll Unlocked");
    };

    const _onScroll = (e) => {
      const currentScrollY = window.scrollY;
      // console.log(inView)
      // console.log("DeltaY:", e.deltaY, "Progress:", progress, "ScrollY:", currentScrollY, "Locked:", isLocked, "HasView:", hasView);

      if (e.deltaY < 0 && !isLocked) {
        setDirection(0);
      }

      if (e.deltaY > 0 && !isLocked && inView && !hasView) {
        setDirection(1);

        lockScroll(currentScrollY);

        // Auto-unlock setelah 3 detik
        setTimeout(() => {
          // console.log("awa")
          setHasView(true);
        }, 800);
      }

      if (e.deltaY > 0 && hasView) {
        // console.log("ewe")
        unlockScroll(currentScrollY);
        setTimeout(() => {
          // console.log("awaw")
          setHasView(false);
        }, 300);
      }
    };

    window.addEventListener("wheel", _onScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", _onScroll);
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress, inView, hasView]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("section_header");
        setHeaderData(headerData);

        const sliderData = await apiHandler("slider_header");

        sliderData.map(async (item) => {
          item.background = await apiImageHandler(item.background);
          return item;
        });
        // console.log(sliderData)
        setSliderData(sliderData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (sliderData) {
    return (
      <div
        className="max-w-[100vw] h-[110vh] flex justify-center items-center bg-white relative z-20"
        ref={ref}
      >
        <div className="w-[59vw] h-fit flex flex-col justify-center items-center text-darkGreen gap-[1.4vw]">
          <div className="text-sm">{headerData.subtitle}</div>
          <div className="text-xl3 text-center">{headerData.title}</div>
          <div className="text-md flex justify-center items-center gap-[0.6vw]">
            Stay at Opatan
            <img src={arrowTopRightBlack} className="w-[0.9vw] h-[0.9vw]"></img>
          </div>
        </div>
        {direction == 1 && inView ? (
          <div className="w-full h-full flex absolute top-0 ">
            {sliderData.map((data, index) => {
              const slidePosition = index % 2 === 0 ? "100vh" : "-100vh"; // Adjusting direction for slides
              const animatePosition = index % 2 === 0 ? "0vh" : "0vh"; // Animation start and end position

              return (
                <motion.div
                  key={index}
                  className="w-[33.3vw] h-full relative z-20 flex justify-center items-center group overflow-hidden"
                  initial={{ top: slidePosition, opacity: 0 }}
                  animate={{
                    top: [slidePosition, animatePosition],
                    opacity: 1,
                  }}
                  transition={{
                    duration: 1,
                    times: [0, 1],
                    ease: "easeInOut",
                    delay: 0,
                  }}
                >
                  <div className="absolute top-0 left-0 w-full h-full !bg-black !transition-opacity !duration-1000 !opacity-0 group-hover:!opacity-50 pointer-events-none z-10"></div>
                  <motion.img
                    className="absolute top-0 left-0 object-cover w-full h-full"
                    src={data?.background || building}
                    whileHover={{ scale: 1.2 }}
                    transition={{ duration: 0.3 }}
                  />
                  <motion.div
                    className="z-10 text-xl text-white !opacity-0 group-hover:!opacity-100"
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    {data?.title || "Loading"}
                  </motion.div>
                </motion.div>
              );
            })}
          </div>
        ) : (
          <div className="w-full h-full flex absolute top-0 ">
            {sliderData.map((data, index) => {
              const slidePosition = index % 2 === 0 ? "0vh" : "0vh";
              const animatePosition = index % 2 === 0 ? "100vh" : "-100vh"; // Adjusting direction for reverse animations

              return (
                <motion.div
                  key={index}
                  className="w-[33.3vw] h-full relative z-20 flex justify-center items-center group overflow-hidden"
                  initial={{ top: slidePosition, opacity: 1 }}
                  animate={{
                    top: [slidePosition, animatePosition],
                    opacity: 0,
                  }}
                  transition={{
                    duration: 1,
                    times: [0, 1],
                    ease: "easeInOut",
                    delay: 0,
                  }}
                >
                  <div className="absolute top-0 left-0 w-full h-full !bg-black !transition-opacity !duration-1000 !opacity-0 group-hover:!opacity-50 pointer-events-none z-10"></div>
                  <motion.img
                    className="absolute top-0 left-0 object-cover w-full h-full"
                    src={data?.background || building}
                    whileHover={{ scale: 1.2 }}
                    transition={{ duration: 0.3 }}
                  />
                  <motion.div
                    className="z-10 text-xl text-white !opacity-0 group-hover:!opacity-100"
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    {data?.title || "Loading"}
                  </motion.div>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

function TrustedBy() {
  const [data, setData] = useState([]);
  const [descriptionData, setDescriptionData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerData = await apiHandler("trusted_marquee");
        headerData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const descriptionData = await apiHandler("trusted_text");
        setDescriptionData(descriptionData);
        setData(headerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center  pl-[5.5vw] bg-white relative z-20">
      <div className="w-full h-full flex justify-start items-center ">
        <div className="text-[1.6vw] py-[2.7vw]">Trusted by</div>
      </div>
      <div className="w-full h-[7.5vw] ">
        <Marquee className="w-full h-full" speed={30}>
          <div className="w-full h-full flex justify-center items-center gap-[10vw]">
            {data.map((item, idx) => (
              <div
                className={`w-[13.8vw] h-full ${idx === data.length - 1 ? "mr-[10vw]" : ""}`}
                key={idx}
              >
                <img
                  src={item.image || building}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>
        </Marquee>
      </div>
      <div className="w-full h-fit mt-[10vw] mb-[18vw]">
        <div className="w-[61vw] h-full text-[1.4vw]">
          {descriptionData.trusted_text_paragraph}
        </div>
      </div>
    </div>
  );
}
