import ArtBuildingHeader, { ArtVirtualTour } from "../../components/ArtComponents"
import { useState, useRef, useEffect } from "react";
import arrow from '../../assets/arrow.png'
import building_white from '../../assets/building.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Footer from "../../components/FooterComponents";

export default function ArtDetailPage() {
    return (
        <div className="max-w-[100vw] h-full flex flex-col justify-center items-center overflow-hidden">
            <ArtBuildingHeader />
            <DetailOverview />
            <Gallery />
            <ArtVirtualTour />
            <Footer />
        </div>
    )
}

function Gallery() {

    const [data, setData] = useState([
        {
            image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
        },
        {
            image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
        },
        {
            image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
        },
        {
            image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
        },
    ]);
    const [index, setIndex] = useState(1);

    return (
        <div className="flex flex-col justify-center items-center w-[89vw] h-full mt-[5.6vw] gap-[2.9vw] border-b-[1px] border-black pb-[5.5vw]">
            <div className="w-full h-[6.3vw] flex justify-between">
                <div >Gallery</div>
                <div className="flex gap-[2.8vw] items-center">
                    <button className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black" id="swiper-next" >
                        <img src={arrow} className="w-[2vw]"></img>
                    </button>
                    <div className="text-lg">
                        <span id="current-slide">{index}</span>/{data.length}
                    </div>
                </div>
            </div>

            {/* Slider Section */}
            <div className="w-[100vw] h-[60vw] overflow-visible ml-[11vw]">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.5}
                    navigation={{
                        nextEl: "#swiper-next",
                        prevEl: "#swiper-prev",
                    }}
                    modules={[Navigation]}
                    onSlideChange={(swiper) => setIndex(swiper.activeIndex + 1)}
                >
                    {data.map((item, idx) => (
                        <SwiperSlide key={idx}>
                            <img
                                src={item.image}
                                alt={`Art ${idx + 1}`}
                                className="w-[72.4vw] h-[60vw] object-cover"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

function DetailOverview() {
    return (
        <div className="flex flex-col justify-center items-center w-[100vw] h-full mt-[7.8vw]">
            <div className="flex flex-col justify-center items-center w-[89vw] h-full gap-[2.8vw]">
                <div className="flex justify-start items-center w-full h-full">
                    Art Name
                </div>
                <div className="w-[89vw] h-[54.3vw] ">
                    <img className="w-full h-full object-cover" src={building_white}></img>
                </div>
            </div>
            <div className="flex justify-center w-[89vw] mt-[7.8vw] h-auto border-black border-b-[1px] pb-[5.5vw]">
                <div className="flex flex-col justify-between w-full gap-[16.8vw]">
                    <div className="flex flex-col gap-[1.7vw] ">
                        <div className="w-full h-fit">
                            Overview
                        </div>
                        <div className="w-[35vw] h-[14.6vw]">
                            We provide a comprehensive, hassle-free arrangement for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of every detail.
                        </div>
                    </div>
                    <div className="w-[18.3vw] h-[3.8vw] flex justify-center items-center bg-darkGreen text-white">
                        Start Virtual Tour
                    </div>
                </div>
                <div className="w-[60%] flex items-stretch">
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-center items-start h-full pl-[1.7vw] pr-[4.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-around items-start h-[80%] pl-[1.7vw] pr-[4.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex items-end ">
                        <div className="w-full border-black border-l-[1px] flex justify-around items-start h-[70%] pl-[1.7vw]">
                            <div className="flex flex-col items-center gap-[5.6vw] mt-[5.1vw]">
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                                <div className="w-[10.3vw] h-[6.7vw]  flex flex-col justify-between items-start">
                                    <div>
                                        INFO
                                    </div>
                                    <div>
                                        Lorem Ipmsum
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}