import { useRef, useState } from "react";
import arrow from "../assets/arrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import home from "../assets/home.jpg";
import { Navigation } from "swiper/modules";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import arrowLeft from "../assets/arrowLeft.png"

export default function AboutUsCard({ data }) {
  const [index, setIndex] = useState(1);
  const swiperRef = useRef(null);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative z-20 bg-white pt-[6.9vw]">
      <div className="w-full h-[6.3vw] flex justify-between items-center px-[5.5vw]">
        <div className="text-md">MEET OUR TEAM</div>
        <div className="flex gap-[2.8vw] items-center">
          {index > 1 && (
            <button
              className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
              id="swiper-prev"
              onClick={() => {
                const swiper = swiperRef.current;
                if (swiper && index > 0) {
                  swiper.slidePrev(); // Pindah ke slide sebelumnya
                }
              }}
            >
              <img src={arrowLeft} className="w-[2vw]" alt="Previous" />
            </button>
          )}
          <button
            className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
            id="swiper-next"
            onClick={() => {
              const swiper = swiperRef.current;
              if (swiper && index < data.length) {
                swiper.slideNext(); // Navigasi ke slide berikutnya
              }
            }}
            disabled={index >= data.length} // Nonaktifkan tombol jika sudah mencapai batas
          >
            <img src={arrow} className="w-[2vw]" alt="Next" />
          </button>
          <div className="text-lg">
            <span id="current-slide">{index}</span>/{data.length}
          </div>
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={false} // Nonaktifkan navigasi default Swiper
        modules={[Navigation]}
        spaceBetween={0}
        slidesPerView={3.05}
        slidesPerGroup={1}
        onSlideChange={(swiper) => {
          // Update index, tetapi batasi ke jumlah data
          setIndex(Math.min(swiper.activeIndex + 1, data.length));
        }}
        className="w-full h-[45vw] flex"
      >
        {data.map((item, idx) => (
          <SwiperSlide
            key={idx}
            className="basis-[32%] w-[31.4vw] !h-[43.2vw] flex justify-center items-center !transition-all !duration-300 !ease-in-out hover:basis-[35%] border-black border-[1px] hover:!h-[45vw]"
          >
            <div className="w-full h-[43.2vw] flex flex-col justify-start ">
              <div className="w-full h-[23.7vw]">
                <img
                  src={item.image}
                  alt={`Art ${idx + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-full px-[1.6vw]">
                <div className="w-full flex flex-col justify-center items-start mt-[2.7vw]">
                  <div className="text-md">{item.Name}</div>
                  <div className="text-sm">{item.jobdesk}</div>
                </div>
                <div className="w-full mt-[1.6vw] text-[0.8vw]">
                  {item.detail}
                </div>
                <div className="w-[8.1vw] mt-[1.6vw] flex justify-between items-center">
                  <LinkedInIcon />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Tambahkan slide bayangan */}
        <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
      </Swiper>
    </div>
  );
}

export function LeftImageChooseOpatan({ data }) {
  return (
    <div className="w-full h-full flex justify-start items-center gap-[9vw]">
      <div className="w-[40.2vw] h-[30.9vw] ">
        <img className="w-full h-full object-contain" src={data.image}></img>
      </div>
      <div className="w-[40vw] h-full flex flex-col justify-center items-start gap-[1.8vw] ">
        <div className="text-md2">{data.title}</div>
        <div className="text-sm2">{data.detail}</div>
      </div>
    </div>
  );
}

export function RightImageChooseOpatan({ data }) {
  return (
    <div className="w-full h-full flex justify-between items-center">
      <div className="w-[45.1vw] h-full flex flex-col justify-center items-start gap-[1.8vw] ">
        <div className="text-md2">{data.title}</div>
        <div className="text-sm2">{data.detail}</div>
      </div>
      <div className="w-[40.2vw] h-[30.9vw] ">
        <img className="w-full h-full object-contain" src={data.image}></img>
      </div>
    </div>
  );
}

export function ImageHeader({ data }) {
  return (
    <div className="w-full h-full pt-[6.9vw] bg-white z-20">
      <div className="w-full h-full flex items-center justify-start relative pl-[5.5vw]">
        <div className="absolute text-xxl font-semibold top-[24.6vw] left-[32vw] ">
          {data.title}
        </div>
        <div className="w-[52.1vw] h-[52.6vw] relative overflow-hidden">
          <img className="w-full h-full object-cover" src={data.image}></img>
          <div className="absolute text-xxl font-semibold top-[24.6vw] left-[26vw] text-white text-nowrap">
            {data.title}
          </div>
        </div>
      </div>
    </div>
  );
}
