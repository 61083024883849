import { useEffect, useState } from "react";
import portrait from "../../assets/portrait.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import card1 from "../../assets/card1.jpeg";
import card2 from "../../assets/card2.jpeg";
import AboutUsCard, {
  ImageHeader,
  LeftImageChooseOpatan,
  RightImageChooseOpatan,
} from "../../components/AboutUsComponent";
import Footer from "../../components/FooterComponents";
import Navbar from "../../components/NavbarComponents";
import { apiHandler, apiImageHandler } from "../../utilities/apiHandler";
import { head } from "motion/react-client";
import { Preloader } from "../Home";

export default function AboutUsPage() {
  const [teamData, setTeamData] = useState([]);
  const [aboutRedyData, setAboutRedyData] = useState([]);
  const [visionData, setVisionData] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const [whyChooseOpatanData, setWhyChooseOpatanData] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const ourTeamData = await apiHandler("meet_our_team");
        ourTeamData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        })
        setTeamData(ourTeamData);

        const aboutRedyData = await apiHandler("abour_redy");
        setAboutRedyData(aboutRedyData);

        const visionData = await apiHandler("our_vision");
        setVisionData(visionData);

        const missionData = await apiHandler("our_mission");
        setMissionData(missionData);
        
        const whyChooseOpatanData = await apiHandler("why_choose_opatan");
        whyChooseOpatanData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        })
        setWhyChooseOpatanData(whyChooseOpatanData);

        const headerData = await apiHandler("about_header");
        headerData.image = await apiImageHandler(headerData.image);

        setHeaderData(headerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center ">
      <Preloader /> 
      <Navbar type="default" />
      <ImageHeader data={headerData} />
      <Description />
      <AboutUsCard data={teamData} />
      <AboutRedySection data={aboutRedyData} />
      <VisionSection data={visionData}/>
      <MissionSection data={missionData}/>
      <WhyChooseOpatanSection data={whyChooseOpatanData} />
      <Footer />
    </div>
  );
}

function Description() {
  return (
    <div className="w-full h-full pl-[5.5vw] pb-[6.9vw] border-b-[1px] border-lightGray relative z-20 bg-white pt-[6.9vw]">
      <div className="w-[48.1vw] text-sm2">
        Lorem ipsum dolor sit amet consectetur. Praesent volutpat ut vitae pharetra dui at diam. Amet ut aenean neque consequat vitae suspendisse. Eget sit at augue consequat. Lorem ipsum dolor sit amet consectetur. Praesent volutpat ut vitae pharetra dui at diam. Amet ut aenean neque consequat vitae suspendisse. Eget sit at augue consequat.Lorem ipsum dolor sit amet consectetur. Praesent volutpat ut vitae pharetra dui at diam. Amet ut aenean neque consequat vitae suspendisse. Eget sit at augue consequat.
      </div>
    </div>
  )
}

function WhyChooseOpatanSection({ data }) {
 

  return (
    <div className="w-full h-full flex flex-col items-center justify-center pt-[2.8vw] pl-[5.5vw] gap-[8vw] relative z-20 bg-white pt-[6.9vw]">
      <div className="w-full h-full flex flex-start items-center text-sm2">
        WHY CHOOSE OPATAN
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center">
        {data.map((data, index) => {
          return index % 2 === 0 ? (
            <RightImageChooseOpatan data={data} key={index} />
          ) : (
            <LeftImageChooseOpatan data={data} key={index} />
          );
        })}
      </div>
    </div>
  );
}

function MissionSection({data}) {

  return (
    <div className="w-full h-full flex flex-col justify-center items-center pt-[2.7vw] pl-[5.5vw] gap-[2.7vw] pb-[6.9vw] border-black border-b-[1px] relative z-20 bg-white pt-[6.9vw]">
      <div className="w-full h-full flex justify-start items-center text-md">
        OUR MISSON
      </div>
      <div class="grid grid-cols-3 gap-[2.7vw] w-full h-full">
        {data.map((item, index) => (
          <div
            key={index}
            className="w-[27.6vw] h-fit flex justify-start items-start gap-[1.6vw]"
          >
            <div className="w-[2.7vw] h-[2.7vw] flex justify-center items-center border-black border-[1px]">
              {index + 1}
            </div>
            <div className="w-[23.1vw] text-md">{item.mission_point}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function VisionSection({data}) {
  return (
    <div className="w-full h-full flex flex-col justify-center items-start pl-[5.5vw] gap-[2.7vw] pb-[2.7vw] border-black border-b-[1px] relative z-20 bg-white pt-[6.9vw]">
      <div className="text-md">OUR VISION</div>
      <div className="w-[58.3vw] word-wrap text-xl">
        {data.vision}
      </div>
    </div>
  );
}

function AboutRedySection({ data }) {
  return (
    <div className="w-full h-full flex justify-start items-center gap-[6vw] relative z-20 bg-white pt-[6.9vw]">
      <div className="h-[34.9vw] w-[40vw] bg-red-200">
        <img className="w-full h-full object-cover" src={portrait}></img>
      </div>
      <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.6vw]">
        <div className="text-xl">{data.title}</div>
        <div className="w-[43.3vw] word-wrap text-sm">{data.detail}</div>
        <div className="w-full h-full flex justify-start items-center mt-[1.1vw]">
          <div className="text-md">Redy Rahadian’s Website</div>
          <ArrowForwardIcon />
        </div>
      </div>
    </div>
  );
}
