import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import realEstate1 from '../../assets/realEstate1.jpg'
import realEstate2 from '../../assets/realEstate2.jpg'
import realEstate3 from '../../assets/realEstate3.jpg'

export default function Test() {

  return (
    <div className='max-w-[100vw] h-[66vw] ' >
      <Parallax pages={1.1} style={{ top: '0', left: '0' }} className="scrollbar-hide !relative">
        <ParallaxLayer offset={0} speed={1.2} >
          <div className='w-[24vw] h-[25.4vw]  absolute top-[2.7vw] left-[26.2vw]'>
            <img src={realEstate1} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1}>
          <div className='w-[22vw] h-[32.7vw]  absolute top-[12.2vw] left-[73.6vw]'>
            <img src={realEstate2} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={5}>
          <div className='w-[59vw] h-[14.1vw] absolute top-[50.6vw] left-[33.6vw] text-[11.1vw]'>
            Real Estate
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1}>
          <div className='w-[32.7vw] h-[39vw] absolute top-[38.1vw] left-[5.5vw] '>
            <img src={realEstate3} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}
