import { ArtDetail, ArtOverview, ArtVirtualTour, GetInTouch, ParallaxRealEstate } from "../../components/ArtComponents";
import Footer from "../../components/FooterComponents";

export default function RealEstatePage() {
    return (
        <div className="max-w-[100vw] h-full flex flex-col items-center justify-center overflow-y-hidden">
            <ParallaxRealEstate />
            <ArtOverview />
            <ArtDetail />
            <ArtVirtualTour />
            <GetInTouch />
            <Footer />

        </div>
    )
}