import { useState, useRef } from "react";
import arrow from "../assets/arrow.png";
import search from "../assets/search.png";
import art_building from "../assets/art_building.jpeg";
import bathub from "../assets/bathub.jpeg";
import arrowDown from "../assets/arrowDown.png";
import overview from "../assets/overview.jpeg";
import detail from "../assets/detail.jpeg";
import { Box, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import realEstate1 from '../assets/realEstate1.jpg'
import realEstate2 from '../assets/realEstate2.jpg'
import realEstate3 from '../assets/realEstate3.jpg'
import getInTouch from '../assets/getInTouch.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export function ArtOverview() {
  const [data, setData] = useState([
    {
      image: "art_1_url",
      overview:
        "We provide a comprehensive, hassle-free arrangement for your visit from overseas, ensuring a smooth and enjoyable experience from start to finish. From personalized airport transfers and premium accommodation to guided site surveys, we take care of every detail.",
    },
    {
      image: "art_2_url",
      overview: "art_2_overview",
    },
    {
      image: "art_3_url",
      overview: "art_3_overview",
    },
    {
      image: "art_4_url",
      overview: "art_4_overview",
    },
  ]);

  const [index, setIndex] = useState(1);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="flex justify-center items-center w-full h-full gap-[8vw] m-[5.5vw]">
        <div className="w-[46.6vw] h-[43.5vw] bg-red-200">
          <img className="w-full h-full object-cover" src={overview}></img>
        </div>
        <div className="flex flex-col justify-start items-start w-[35%] h-full gap-[1.7vw]">
          <div>Overview</div>
          <div>{data[0].overview}</div>
        </div>
      </div>
    </div>
  );
}

export function ArtDetail() {
  const [index, setIndex] = useState(1);
  const swiperRef = useRef(null);
  const [data, setData] = useState([
    {
      image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one- of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transforms your dream home into a living masterpiece.",
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one- of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transforms your dream home into a living masterpiece.",
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one- of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transforms your dream home into a living masterpiece.",
    },
  ]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-[1.3vw]">
      <div className="w-full flex gap-[2.8vw] items-center justify-end pr-[5.5vw]">
        <button
          className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
          id="swiper-next"
          onClick={() => {
            const swiper = swiperRef.current;
            if (swiper && index < data.length) {
              swiper.slideNext(); // Navigasi ke slide berikutnya
            }
          }}
          disabled={index >= data.length} // Nonaktifkan tombol jika sudah mencapai batas
        >
          <img src={arrow} className="w-[2vw]" alt="Next" />
        </button>
        <div className="text-lg">
          <span id="current-slide">{index}</span>/{data.length}
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={false}
        modules={[Navigation]}
        spaceBetween={0}
        slidesPerView={3}
        slidesPerGroup={1}
        onSlideChange={(swiper) => {
          setIndex(Math.min(swiper.activeIndex + 1, data.length));
        }}
        className="w-full h-[44vw] flex"
      >
        {data.map((item, idx) => (
          <SwiperSlide
            key={idx}
            className="basis-[33.3%] w-[33.3vw] !h-[42.4vw] flex justify-center items-center !transition-all !duration-300 !ease-in-out hover:basis-[35%] border-black border-[1px] hover:!h-[44vw] "
          >
            <div className="w-full h-[43.2vw] flex flex-col justify-start ">
              <div className="w-full h-[23.7vw]">
                <img
                  src={item.image}
                  alt={`Art ${idx + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-full px-[1.6vw]">
                <div className="w-full flex flex-col justify-center items-start mt-[2.7vw]">
                  <div>{item.name}</div>
                  <div>{item.position}</div>
                </div>
                <div className="w-full mt-[1.6vw]">{item.desc}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Tambahkan slide bayangan */}
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
      </Swiper>
    </div>
  );
}

export function ArtVirtualTour() {
  const [data, setData] = useState([
    {
      image: "art_1_url",
      title: "art_1_title",
      desc: "art_1_desc",
      link: "art_1_link",
    },
    {
      image: "art_2_url",
      title: "art_2_title",
      desc: "art_2_desc",
      link: "art_2_link",
    },
    {
      image: "art_3_url",
      title: "art_3_title",
      desc: "art_3_desc",
      link: "art_3_link",
    },
    {
      image: "art_4_url",
      title: "art_4_title",
      desc: "art_4_desc",
      link: "art_4_link",
    },
  ]);
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center">
      <div className="w-full h-[10.5vw] flex items-center gap-[7.4vw] pl-[5.6vw] ">
        <Box
          sx={{ display: "flex", alignItems: "flex-end" }}
          className="w-[43.8vw] h-[5vw] border-b-[1px] border-black flex justify-start items-center gap-[1.4vw]"
        >
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="Search Art"
            variant="standard"
            className="w-[43.8vw]"
            InputProps={{ disableUnderline: true }}
            sx={{
              "& .MuiInputLabel-root": { color: "black" },
              "& .MuiInputLabel-root.Mui-focused": { color: "black" },
            }}
          />
        </Box>
        <div className="w-[7vw] h-[5vw] flex justify-start items-center gap-[1.6vw]">
          <div>Filter By</div>
          <div className="w-[0.9vw] h-[0.6vw] ">
            <img className="w-full h-full object-contain" src={arrowDown}></img>
          </div>
        </div>
      </div>
      <div className="grid grid-rows-2 grid-cols-2 w-full h-[94vw] gap-[2.8vw] px-[4.5vw] py-[2.8vw]">
        {data.map((data, index) => (
          <div
            key={index}
            className="w-full h-full bg-red-300 group text-white"
            style={{ backgroundImage: `url(${bathub})` }}
          >
            <div className="flex flex-col w-full h-full pt-[1.8vw] pl-[3.1vw] pr-[3.2vw] pb-[3.6vw] justify-between group-hover:bg-black group-hover:bg-opacity-20 transition">
              <div className="hidden group-hover:block">{data.title}</div>

              <div className="hidden group-hover:flex justify-end items-center pr-[7.4vw]">
                <div className="w-[14vw] h-[3.5vw] flex justify-center items-center bg-darkGreen bg-opacity-30">
                  Virtual Tour
                </div>
              </div>

              <div className="hidden group-hover:flex w-full justify-between items-center">
                <div>Share</div>
                <div>Read More</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function GetInTouch() {
  return (
    <div className="w-full h-[34.9vw] text-white">
      <div className="w-full h-full flex flex-col justify-center items-center py-[11.1vw] gap-[2.2vw] relative bg-gradient-to-b from-black/0 to-black/70">
        <img src={getInTouch} className="w-full h-full object-cover absolute z-[-10] "></img>
        <div className="text-[4.4vw]">GET IN TOUCH</div>
        <div className="flex flex-col justify-center items-center gap-[4.4vw] w-full h-full text-[1.1vw]">
          <div className="">
            Let’s start a conversation today and bring your vision to life.
          </div>
          <div className="w-[8.2vw] h-[3vw] flex justify-center items-center border-[1px] border-white">
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ArtBuildingHeader() {
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  return (
    <div className="w-full h-[71.6vw] flex flex-col justify-center items-end relative">
      <svg className="absolute w-[56.7vw] h-[14.1vw] text-[11.1vw] right-[30.5vw] top-[30vw]  pointer-events-none">
        <defs>
          <mask id="outsideMask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <rect
              x="31vw"
              y="0"
              width="12.7vw"
              height="100%"
              fill="black"
              style={{
                transition: "opacity 0.2s ease-in-out",
                opacity: hovered ? 1 : 0,
              }}
            />
            <rect
              x="45vw"
              y="0"
              width="12.7vw"
              height="100%"
              fill="black"
              style={{
                transition: "opacity 0.2s ease-in-out",
                opacity: hovered2 ? 1 : 0,
              }}
            />
          </mask>
        </defs>
        <text
          x="0"
          y="70%"
          fontSize="10.4vw"
          mask="url(#outsideMask)"
          fill="#13361C"
        >
          Art Building
        </text>
      </svg>
      <div className="flex items-center w-[56.7vw] h-full ">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="w-fit h-full px-[0.6vw] flex items-end pb-[9.6vw] "
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={art_building}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-start pt-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={art_building}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-end pb-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={art_building}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-start pt-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={art_building}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}


export function ParallaxRealEstate() {
    return (
        <div className='w-[100vw] h-[66vw]' >
            <Parallax pages={1.1} style={{ top: '0', left: '0' }} className="scrollbar-hide !relative">
                <ParallaxLayer offset={0} speed={1.2} >
                    <div className='w-[24vw] h-[25.4vw]  absolute top-[2.7vw] left-[26.2vw]'>
                        <img src={realEstate1} className='w-full h-full object-cover'></img>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={1}>
                    <div className='w-[22vw] h-[32.7vw]  absolute top-[12.2vw] left-[73.6vw]'>
                        <img src={realEstate2} className='w-full h-full object-cover'></img>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={5}>
                    <div className='w-[59vw] h-[14.1vw] absolute top-[50.6vw] left-[33.6vw] text-[11.1vw]'>
                        Real Estate
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={1}>
                    <div className='w-[32.7vw] h-[39vw] absolute top-[38.1vw] left-[5.5vw] '>
                        <img src={realEstate3} className='w-full h-full object-cover'></img>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    )
}
