import { useParams } from "react-router-dom";
import { ConnectWithUs } from "../../components/ContactComponents";
import Footer from "../../components/FooterComponents";

export default function ContactPage(){
    const { type } = useParams()

    return (
        <div className="flex flex-col justify-center items-center overflow-hidden max-w-[100vw]">
            <ConnectWithUs/>
            <Footer/>
        </div>
    )
}