import { TextField } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import opatanFooter from '../assets/opatanFooter.png'

export default function Footer() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  const featuredMenus = [
    { "name": "Art Building", "link": "/home" },
    { "name": "Art Interior", "link": "/about" },
    { "name": "Real Estate", "link": "/contact" }
  ];

  const companyMenus = [
    { "name": "About", "link": "/home" },
    { "name": "Gallery", "link": "/about" },
    { "name": "Carrers", "link": "/contact" },
    { "name": "Contact Us", "link": "/contact" },
    { "name": "FAQ", "link": "/faq" }
  ];

  const socialsMenus = [
    { "name": "Linkedin", "link": "/home" },
    { "name": "IG", "link": "/about" },
    { "name": "Facebook", "link": "/contact" },
    { "name": "Tiktok", "link": "/contact" },
    { "name": "Youtube", "link": "/contact" }
  ];

  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center px-[5.6vw] sticky bottom-0 z-0 py-[4vw]">
      <div className="w-full h-full text-sm text-transparent">
        Newsletter
      </div>
      <div className="w-full h-full flex justify-center items-center gap-[10vw]">
        <div className="w-[50%] h-full flex flex-col justify-center items-start mt-[2.8vw] gap-[5.6vw] ">
          <div className="w-full h-full flex justify-start items-center gap-[0.7vw]">
            <TextField id="input-with-sx" label="Email" variant="standard" className="w-[16.8vw] !text-md" InputProps={{ disableUnderline: true, }} sx={{
              "& .MuiInputLabel-root": { color: "black", fontSize: "1.2vw", }, "& .MuiInputLabel-root.Mui-focused": { color: "black", fontSize: "1.2vw", }, "& .MuiInputBase-root": {
                borderBottom: "1px solid black",
                transition: "none",
                height: "3.8vw",
              },
              "& .MuiInputBase-root:focus-within": {
                borderBottom: "1px solid black",
              },
              "& .MuiInputBase-input": {
                padding: 0,
                height: "100%",
                fontSize: "1.2vw",
              },
            }}
            />
            <div className="text-sm2">
              Subscribe
            </div>
          </div>
          <div className="w-full h-full flex justify-start items-start gap-[8.9vw]">
            <FooterMenus menus={featuredMenus} title="Featured" />
            <FooterMenus menus={companyMenus} title="Company" />
            <FooterMenus menus={socialsMenus} title="Socials" />
          </div>
        </div>
        <div className="w-[50%] h-full flex flex-col justify-center items-start mt-[2.8vw]  gap-[5.6vw] pb-[2.8vw]">
          <div className="w-full h-full flex justify-start items-center gap-[2.8vw]">
            <div className="w-[11.8vw] h-[3.8vw] flex justify-center items-center bg-darkGreen text-white text-sm">
              Catalogue
            </div>
            <div className="w-[15.7vw] h-[3.8vw] flex justify-center items-center border-[1px] border-black text-sm">
              Company Profile
            </div>
          </div>
          <div className="w-full h-full flex flex-col justify-start items-start gap-[2.8vw]">
            <div className="text-sm">
              Contact
            </div>
            <div className="w-full h-full flex items-center gap-[2.8vw] ">
              <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.1vw]">
                <div className="text-sm">
                  Whatsapp
                </div>
                <div className="text-sm">
                  +(62) 813 8337 8582
                </div>
              </div>
              <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.1vw]">
                <div className="text-sm">
                  Email
                </div>
                <div className="text-sm">
                  info@opatan.id
                </div>
              </div>
            </div>

            <div className="w-full h-full flex flex-col justify-center items-start gap-[1.1vw]">
              <div className="text-sm">
                Address
              </div>
              <div className="text-sm">
                Jl. Subak Belaki No. 60, Sukawati, Gianyar, Bali 80582
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[89vw] h-full border-b-[1px] border-black pt-[1vw]">
        <div className="w-[89vw] h-[16.8vw] flex justify-center items-center absolute bottom-[12vw]">
          <img src={opatanFooter} className="w-full h-full object-contain"></img>
        </div>
      </div>
      <div className=" w-full h-full flex justify-center items-center text-sm pt-[4.5vw] ">
        <div className="w-[93vw] h-full flex justify-between items-center ">
          <div>
            2024 Opatan, All Rights Reserved.
          </div>
          <div>
            |
          </div>
          <div>
            Terms of Service
          </div>
          <div>
            |
          </div>
          <div>
            Privacy Policy
          </div>
        </div>
        <div className="w-full h-full justify-end items-center flex  ">
          <div className="cursor-pointer flex gap-[0.7vw] justify-center items-center">
            <div onClick={scrollToTop}>
              Back To Top
            </div>
            <ArrowUpwardIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

function FooterMenus({ title, menus }) {
  return (
    <div className="w-fit h-full flex flex-col justify-center items-start gap-[2.8vw]">
      <div className="text-darkGreen text-sm">{title}</div>
      <div className="flex flex-col justify-center items-start gap-[1.4vw] text-md">
        {
          menus.map((menu, id) => {
            return (
              <div id={id} className="text-sm2">
                {menu.name}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}