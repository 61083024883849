import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useState } from "react";
import Footer from "../../components/FooterComponents";

export default function FAQPage() {
  return (
    <div className="max-w-[100vw] h-full flex flex-col px-[5.5vw] pt-[5.5vw] pb-[6.9vw] gap-[2.7vw]">
      <div className="w-[75.3vw] h-[7vw] flex justify-start items-center">
        Frequently Asked Questions
      </div>
      <div className="w-full h-[38.1vw] flex justify-center items-center gap-[2.5vw]">
        <FAQAccordion />
        <ContactUsSection />
      </div>
      <Footer />
    </div>
  );
}

function FAQAccordion() {
  const [data, setData] = useState([
    {
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one-of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transform your dream home into a living masterpiece.",
    },
    {
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one-of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transform your dream home into a living masterpiece.",
    },
    {
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one-of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transform your dream home into a living masterpiece.",
    },
    {
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one-of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transform your dream home into a living masterpiece.",
    },
    {
      title: "ART HOUSE",
      desc: "Opatan is the perfect choice for homeowners who desire to build a one-of-a-kind, artistic house. We specialize in creating fully customized designs that seamlessly blend functionality with artistry, Opatan can transform your dream home into a living masterpiece.",
    },
  ]);

  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => () => {
    setExpanded((prev) => (prev === panel ? panel : panel));
  };

  return (
    <div className="w-[50%] h-full flex flex-col justify-between items-center ">
      {data.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          square
          className="!rounded-none !border-lightGray !border-[1px] w-full "
          sx={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="!flex !justify-start !items-center !h-[4.5vw]"
          >
            <div>{item.title}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{item.desc}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

function ContactUsSection() {
  return (
    <div className="w-[50%] h-full flex justify-start items-start flex-col gap-[2.7vw] border-lightGray border-[1px] px-[2.7vw] pt-[2.7vw]">
      <div className="flex flex-col justify-start items-start">
        <div className="w-full h-full">MORE QUESTION</div>
        <div className="w-full h-[8.3vw]">
          Lorem ipsum dolor sit amet consectetur. Adipiscing dictumst
          pellentesque ut ultrices tristique porttitor feugiat suspendisse odio.
          Sem ipsum dis iaculis eget magna. Volutpat mi in varius duis.
          Phasellus nunc gravida sit nisl aliquet.
        </div>
      </div>
      <div className="w-[10.4vw] h-[3vw] bg-darkGreen text-white flex justify-center items-center">
        Contact Us
      </div>
    </div>
  );
}
