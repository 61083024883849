import axios from 'axios';

async function apiHandler(url) {
    try {
        const response = await axios.get('https://admin.opatan.id/items/' + url);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageHandler(id) {
    try {
        return 'https://admin.opatan.id/assets/' + id
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageHeaderHandler(type, data) {
    data.images = []
    try {
        // Gunakan Promise.all untuk menunggu semua permintaan async selesai
        const results = await Promise.all(
            data.map(async (item) => {
                let result = ''
                if (type == "real_estate") {
                    result = await axios.get(
                        'https://admin.opatan.id/items/real_estate_detail_header_files/' + item
                    );
                } else {
                    result = await axios.get(
                        'https://admin.opatan.id/items/art_' + type + '_detail_header_files/' + item
                    );
                }
                return await apiImageHandler(result.data.data.directus_files_id);
            })
        );

        return results
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageFilesHandler(type, data) {
    data.images = []
    console.log(data)
    try {
        // Gunakan Promise.all untuk menunggu semua permintaan async selesai
        const results = await Promise.all(
            data.map(async (item) => {
                let result = ''
                if (type == "real_estate") {
                    result = await axios.get(
                        'https://admin.opatan.id/items/real_estate_lists_files/' + item
                    );
                } else {
                    result = await axios.get(
                        'https://admin.opatan.id/items/art_' + type + '_lists_files/' + item
                    );
                }
                return await apiImageHandler(result.data.data.directus_files_id);
            })
        );

        return results
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}


export { apiHandler, apiImageHandler, apiImageFilesHandler, apiImageHeaderHandler };
