import { useState } from "react";
import portrait from "../../assets/portrait.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import card1 from "../../assets/card1.jpeg";
import card2 from "../../assets/card2.jpeg";
import AboutUsCard, {
  LeftImageChooseOpatan,
  RightImageChooseOpatan,
} from "../../components/AboutUsComponent";
import Footer from "../../components/FooterComponents";

export default function  AboutUsPage() {
  return (
    <div className="max-w-[100vw] h-full flex flex-col justify-center items-center pt-[6.9vw] gap-[6.9vw] overflow-hidden">
      <AboutRedySection />
      <AboutUsCard />
      <VisionSection />
      <MissionSection />
      <WhyChooseOpatanSection />
      <Footer />
    </div>
  );
}


function WhyChooseOpatanSection() {
  const [data, setData] = useState([
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card1,
    },
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card2,
    },
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card1,
    },
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card2,
    },
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card1,
    },
    {
      title: "Fine Art Design",
      desc: "Opatan is the first art building that is designed by a world class fine art artist. This unique collaboration between architecture and fine art redefines the boundaries of traditional building design. This collaboration results in buildings that are visually stunning, deeply meaningful, and uniquely tailored to reflect the essence of both the environment and the individuals who inhabit them.",
      image: card2,
    },
  ]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center pt-[2.8vw] pl-[5.5vw] gap-[8vw]">
      <div className="w-full h-full flex flex-start items-center">
        WHY CHOOSE OPATAN
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center">
        {data.map((data, index) => {
          return index % 2 === 0 ? (
            <RightImageChooseOpatan data={data} key={index} />
          ) : (
            <LeftImageChooseOpatan data={data} key={index} />
          );
        })}
      </div>
    </div>
  );
}

function MissionSection() {
  const [data, setData] = useState([
    "Create extraordinary art buildings that seamlessly blend fine art with functional architecture.",
    "Incorporate sustainability and innovation into every project, ensuring environmentally responsible practices.",
    "Create extraordinary art buildings that seamlessly blend fine art with functional architecture.",
    "Incorporate sustainability and innovation into every project, ensuring environmentally responsible practices.",
    "Create extraordinary art buildings that seamlessly blend fine art with functional architecture.",
    "Incorporate sustainability and innovation into every project, ensuring environmentally responsible practices.",
    "Create extraordinary art buildings that seamlessly blend fine art with functional architecture.",
    "Incorporate sustainability and innovation into every project, ensuring environmentally responsible practices.",
    "Create extraordinary art buildings that seamlessly blend fine art with functional architecture.",
    "Incorporate sustainability and innovation into every project, ensuring environmentally responsible practices.",
  ]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center pt-[2.7vw] pl-[5.5vw] gap-[2.7vw] pb-[6.9vw] border-black border-b-[1px]">
      <div className="w-full h-full flex justify-start items-center ">
        OUR MISSON
      </div>
      <div class="grid grid-cols-3 gap-[2.7vw] w-full h-full">
        {data.map((desc, index) => (
          <div
            key={index}
            className="w-[27.6vw] h-fit flex justify-start items-start gap-[1.6vw]"
          >
            <div className="w-[2.7vw] h-[2.7vw] flex justify-center items-center border-black border-[1px]">
              {index + 1}
            </div>
            <div className="w-[23.1vw]">{desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function VisionSection() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-start pl-[5.5vw] gap-[2.7vw] pb-[2.7vw] border-black border-b-[1px]">
      <div>OUR VISION</div>
      <div className="w-[58.3vw] word-wrap">
        Turning building into an art masterpiece, embracing nature, by
        sustainable building and elevating each project with the timeless touch
        of fine art.
      </div>
    </div>
  );
}

function AboutRedySection() {
  return (
    <div className="w-full h-full flex justify-start items-center gap-[6vw]">
      <div className="h-[34.9vw] w-[40vw] bg-red-200">
        <img className="w-full h-full object-cover" src={portrait}></img>
      </div>
      <div className="w-fit h-full flex flex-col justify-center items-start gap-[1.6vw]">
        <div>More About Redy Rahadian</div>
        <div className="w-[43.3vw] word-wrap">
          Lorem ipsum dolor sit amet consectetur. Praesent volutpat ut vitae
          pharetra dui at diam. Amet ut aenean neque consequat vitae
          suspendisse. Eget sit at augue consequat. Lorem ipsum dolor sit amet
          consectetur. Praesent volutpat ut vitae pharetra dui at diam. Amet ut
          aenean neque consequat vitae suspendisse.
        </div>
        <div className="w-full h-full flex justify-start items-center mt-[1.1vw]">
          <div>Redy Rahadian’s Website</div>
          <ArrowForwardIcon />
        </div>
      </div>
    </div>
  );
}
