import { useRef, useState } from "react";
import arrow from "../assets/arrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";


export default function AboutUsCard() {
    const [index, setIndex] = useState(1);
    const swiperRef = useRef(null);
    const [data, setData] = useState([
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
        {
            image:
                "https://plus.unsplash.com/premium_photo-1672423154405-5fd922c11af2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
            name: "samuel rezky rahadian",
            position: "Management",
            desc: "With 7 years of experience in prefabricated building, AZ’s expertise encompasses the entire lifecycle of construction development.",
        },
    ]);

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="w-full h-[6.3vw] flex justify-between items-center px-[5.5vw]">
                <div>MEET OUR TEAM</div>
                <div className="flex gap-[2.8vw] items-center">
                    <button
                        className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
                        id="swiper-next"
                        onClick={() => {
                            const swiper = swiperRef.current;
                            if (swiper && index < data.length) {
                                swiper.slideNext(); // Navigasi ke slide berikutnya
                            }
                        }}
                        disabled={index >= data.length} // Nonaktifkan tombol jika sudah mencapai batas
                    >
                        <img src={arrow} className="w-[2vw]" alt="Next" />
                    </button>
                    <div className="text-lg">
                        <span id="current-slide">{index}</span>/{data.length}
                    </div>
                </div>
            </div>
            <Swiper
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                navigation={false} // Nonaktifkan navigasi default Swiper
                modules={[Navigation]}
                spaceBetween={0}
                slidesPerView={3.33}
                slidesPerGroup={1}
                onSlideChange={(swiper) => {
                    // Update index, tetapi batasi ke jumlah data
                    setIndex(Math.min(swiper.activeIndex + 1, data.length));
                }}
                className="w-full h-[45vw] flex"
            >
                {data.map((item, idx) => (
                    <SwiperSlide
                        key={idx}
                        className="basis-[30%] w-[31.4vw] !h-[43.2vw] flex justify-center items-center !transition-all !duration-300 !ease-in-out hover:basis-[35%] border-black border-[1px] hover:!h-[45vw]"
                    >
                        <div className="w-full h-[43.2vw] flex flex-col justify-start ">
                            <div className="w-full h-[23.7vw]">
                                <img
                                    src={item.image}
                                    alt={`Art ${idx + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="w-full px-[1.6vw]">
                                <div className="w-full flex flex-col justify-center items-start mt-[2.7vw]">
                                    <div>{item.name}</div>
                                    <div>{item.position}</div>
                                </div>
                                <div className="w-full mt-[1.6vw]">{item.desc}</div>
                                <div className="w-[8.1vw] mt-[1.6vw] flex justify-between items-center">
                                    <FacebookIcon />
                                    <LinkedInIcon />
                                    <InstagramIcon />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                {/* Tambahkan slide bayangan */}
                <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
                <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
                <SwiperSlide className="basis-[30%] w-[31.4vw]"></SwiperSlide>
            </Swiper>
        </div>
    );
}

export function LeftImageChooseOpatan({ data }) {
    return (
        <div
            className="w-full h-full flex justify-start items-center gap-[9vw]"
        >
            <div className="w-[40.2vw] h-[30.9vw] ">
                <img className="w-full h-full object-contain" src={data.image}></img>
            </div>
            <div className="w-[40vw] h-full flex flex-col justify-center items-start gap-[1.8vw] ">
                <div>{data.title}</div>
                <div>{data.desc}</div>
            </div>
        </div>
    );
}

export function RightImageChooseOpatan({ data }) {
    return (
        <div
            className="w-full h-full flex justify-between items-center"
        >
            <div className="w-[45.1vw] h-full flex flex-col justify-center items-start gap-[1.8vw] ">
                <div>{data.title}</div>
                <div>{data.desc}</div>
            </div>
            <div className="w-[40.2vw] h-[30.9vw] ">
                <img className="w-full h-full object-contain" src={data.image}></img>
            </div>
        </div>
    );
}


