import { Link, Route } from "react-router-dom";
import opatan from "../assets/opatan.png";
import "../components/CSS/style.css";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useScroll } from "@react-spring/web";
import { motion } from "framer-motion";

export default function Navbar({ type }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const { scrollYProgress } = useScroll();

  useEffect(() => {
    const handleWheel = (e) => {
      let currentScrollY = scrollYProgress.get();
      console.log(currentScrollY);
      if (currentScrollY < 0.002 && e.deltaY < 0) {
        // Kalau di paling atas dan user scroll ke atas, hide navbar
        console.log("di false");
        setShowNavbar(false);
      } else if (currentScrollY && e.deltaY > 0) {
        // Kalau scroll ke bawah, tampilkan navbar
        console.log("di true");
        setShowNavbar(true);
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [scrollYProgress, showNavbar]);

  const renderNavbar = () => {
    switch (type) {
      case "default":
        return (
          <motion.nav
            animate={{ y: showNavbar ? [0, -100, 0] : [-100, 0] }}
            transition={{ duration: showNavbar ? 0.8 : 0.5, ease: "easeInOut" }}
            className={`z-30 sticky top-0 ${showNavbar ? "bg-white/50": "bg-white"}`}
          >
            <ul className={`dropDown ${showDropdown ? "show" : "close"} `}>
              <div className="flex w-full ">
                <div className="flex justify-start w-full h-full">
                  <li className="w-[8.33vw] h-[5vw]  object-contain p-0">
                    <a href="#">
                      <img className="w-full h-100% " src={opatan} alt="" />
                    </a>
                  </li>
                  <li className="close-button ">
                    <a href="#">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleDropdown}
                      >
                        <g id="Hamburger">
                          <line
                            id="Line 1"
                            x1="13.3536"
                            y1="12.6464"
                            x2="27.3536"
                            y2="26.6464"
                            stroke="#13361C"
                          />
                          <line
                            id="Line 2"
                            x1="12.6464"
                            y1="26.7885"
                            x2="26.7886"
                            y2="12.6464"
                            stroke="#13361C"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                </div>
              </div>
              <div className="w-full h-full flex justify-center items-center">
                <div className="w-[50%] h-full flex flex-col justify-between items-start">
                  <div className="gap-[2.778vw]">
                    <li className="ArtPages">
                      <Link to="/art/interior">Art Interior</Link>
                    </li>
                    <li className="ArtPages">
                      <Link to="/art/building">Art Building</Link>
                    </li>
                    <li className="ArtPages">
                      <Link to="/real-estate">Real Estate</Link>
                    </li>
                  </div>
                  <div className="">
                    <div className="">
                      <li className="p-0">Social Media</li>
                      <div className="flex gap-[1.667vw]">
                        <li className="Socials">
                          <a href="#">LinkedIn</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Facebook</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Youtube</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Instagram</a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] h-full flex flex-col justify-center items-center ">
                  <div className="w-full h-full flex flex-col justify-between items-center ">
                    <div className="flex flex-col w-fit h-fit justify-between ">
                      <li className="">
                        <Link className="" to="/about-us">
                          About
                        </Link>
                      </li>
                      <li className="">
                        <a className="" href="#">
                          Gallery
                        </a>
                      </li>
                      <li className="">
                        <a className="" href="#">
                          Careers
                        </a>
                      </li>
                      <li className="">
                        <a className="" href="#">
                          Process
                        </a>
                      </li>
                      <li className="">
                        <Link className="" to="/faq">
                          FAQ
                        </Link>
                      </li>
                      <li className="">
                        <Link className="" to="/contact">
                          Contact Us
                        </Link>
                      </li>
                    </div>
                    <div className="w-full h-[14.861vw] flex flex-col justify-center items-center " >
                      <div className="w-full h-full flex flex-col justify-between">
                        <div className="">Newsletter</div>
                        <div className="flex justify-start items-center gap-[0.7vw]">
                          <TextField
                            id="input-with-sx"
                            label="Email"
                            variant="standard"
                            className="w-[16.8vw]"
                            InputProps={{ disableUnderline: true }}
                            sx={{
                              "& .MuiInputLabel-root": { color: "black" },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiInputBase-root": {
                                borderBottom: "1px solid black",
                                transition: "none",
                                height: "3.8vw",
                              },
                              "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black",
                              },
                              "& .MuiInputBase-input": {
                                padding: 0,
                                height: "100%",
                              },
                            }}
                          />
                          <div className="">Subscribe</div>
                        </div>
                        <div className="flex justify-start item-start gap-[2.778vw]">
                          <li
                            style={{ marginRight: "initial" }}
                            className="p-[0]"
                          >
                            <a className="SoP">
                              <button href="#">Stay at Opatan</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Company Profile</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Catalogue</button>
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <ul >
              <li className="w-[8.33vw] h-[5vw]  object-contain p-0 ">
                <a href="#">
                  <img
                    className={`w-full h-100% ${
                      showNavbar ? "scale-100" : "scale-100"
                    }`}
                    src={opatan}
                    alt=""
                  />
                </a>
              </li>
              <li>
                <Link to="/art/building">Art Building</Link>
              </li>
              <li>
                <Link to="/art/interior">Art Interior</Link>
              </li>
              <li>
                <Link to="/real-estate">Real Estate</Link>
              </li>
              <li>
                <a className="SoP">
                  <button href="#">Stay at Opatan</button>
                </a>
              </li>
              <li>
                <a>|</a>
              </li>
              <li>
                <a href="#">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleDropdown}
                  >
                    <g id="mdi:menu">
                      <path
                        id="Vector"
                        d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z"
                        fill="#13361C"
                      />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </motion.nav>
        );
      case "artDetail":
        return (
          <nav>
            <ul className={`dropDown ${showDropdown ? "show" : "close"}`}>
              <div className="flex w-full">
                <div className="flex justify-start w-full h-full">
                  <li className="w-[8.33vw] h-[5vw]  object-contain p-0">
                    <a href="#">
                      <img className="w-full h-100%" src={opatan} alt="" />
                    </a>
                  </li>
                  <li className="close-button">
                    <a href="#">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleDropdown}
                      >
                        <g id="Hamburger">
                          <line
                            id="Line 1"
                            x1="13.3536"
                            y1="12.6464"
                            x2="27.3536"
                            y2="26.6464"
                            stroke="#13361C"
                          />
                          <line
                            id="Line 2"
                            x1="12.6464"
                            y1="26.7885"
                            x2="26.7886"
                            y2="12.6464"
                            stroke="#13361C"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                </div>
              </div>
              <div className="w-full h-full flex justify-center items-center ">
                <div className="w-[50%] h-full flex flex-col justify-start items-start gap-[17.292vw] ">
                  <div className="gap-[2.778vw]">
                    <li className="ArtPages">
                      <a href="#">Art Interior</a>
                    </li>
                    <li className="ArtPages">
                      <a href="#">Art Building</a>
                    </li>
                    <li className="ArtPages">
                      <a href="#">Real Estate</a>
                    </li>
                  </div>
                  <div className="">
                    <div className="">
                      <li className="p-0">Social Media</li>
                      <div className="flex gap-[1.667vw]">
                        <li className="Socials">
                          <a href="#">LinkedIn</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Facebook</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Youtube</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Instagram</a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] h-full flex flex-col justify-center items-center">
                  <div className="w-full h-full flex flex-col justify-center items-center gap-[13.194vw]">
                    <div className="flex flex-col w-[8.889vw] h-[22.222vw] gap-[1.944vw]">
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          About
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Gallery
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Careers
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Process
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          FAQ
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Contact Us
                        </a>
                      </li>
                    </div>
                    <div className="w-full h-[14.861vw] flex flex-col justify-center items-center ml-[7.083vw] gap-[5.556vw]">
                      <div className="w-full h-full flex flex-col justify-start gap-[2.778vw]">
                        <div className="">Newsletterasd</div>
                        <div className="flex justify-start items-center gap-[0.7vw]">
                          <TextField
                            id="input-with-sx"
                            label="Email"
                            variant="standard"
                            className="w-[16.8vw]"
                            InputProps={{ disableUnderline: true }}
                            sx={{
                              "& .MuiInputLabel-root": { color: "black" },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiInputBase-root": {
                                borderBottom: "1px solid black",
                                transition: "none",
                                height: "3.8vw",
                              },
                              "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black",
                              },
                              "& .MuiInputBase-input": {
                                padding: 0,
                                height: "100%",
                              },
                            }}
                          />
                          <div className="">Subscribe</div>
                        </div>
                        <div className="flex justify-start item-start gap-[2.778vw]">
                          <li
                            style={{ marginRight: "initial" }}
                            className="p-[0]"
                          >
                            <a className="SoP">
                              <button href="#">Stay at Opatan</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Company Profile</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Catalogue</button>
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <ul>
              <li className="w-[8.33vw] h-[5vw]  object-contain p-0">
                <a href="#">
                  <img className="w-full h-100%" src={opatan} alt="" />
                </a>
              </li>
              <li>
                <a href="#">Art House</a>
              </li>
              <li>
                <a href="#">Art Villa</a>
              </li>
              <li>
                <a href="#">Art Tree House</a>
              </li>
              <li>
                <a href="#">Art Commercial</a>
              </li>
              <li>
                <a>|</a>
              </li>
              <li>
                <a href="#">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleDropdown}
                  >
                    <g id="mdi:menu">
                      <path
                        id="Vector"
                        d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z"
                        fill="#13361C"
                      />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        );
      case "realEstate":
        return (
          <nav>
            <ul className={`dropDown ${showDropdown ? "show" : "close"}`}>
              <div className="flex w-full">
                <div className="flex justify-start w-full h-full">
                  <li className="w-[8.33vw] h-[5vw]  object-contain p-0">
                    <a href="#">
                      <img className="w-full h-100%" src={opatan} alt="" />
                    </a>
                  </li>
                  <li className="close-button">
                    <a href="#">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleDropdown}
                      >
                        <g id="Hamburger">
                          <line
                            id="Line 1"
                            x1="13.3536"
                            y1="12.6464"
                            x2="27.3536"
                            y2="26.6464"
                            stroke="#13361C"
                          />
                          <line
                            id="Line 2"
                            x1="12.6464"
                            y1="26.7885"
                            x2="26.7886"
                            y2="12.6464"
                            stroke="#13361C"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                </div>
              </div>
              <div className="w-full h-full flex justify-center items-center">
                <div className="w-[50%] h-full flex flex-col justify-start items-start gap-[17.292vw]">
                  <div className="gap-[2.778vw]">
                    <li className="ArtPages">
                      <a href="#">Art Interior</a>
                    </li>
                    <li className="ArtPages">
                      <a href="#">Art Building</a>
                    </li>
                    <li className="ArtPages">
                      <a href="#">Real Estate</a>
                    </li>
                  </div>
                  <div className="">
                    <div className="">
                      <li className="p-0">Social Media</li>
                      <div className="flex gap-[1.667vw]">
                        <li className="Socials">
                          <a href="#">LinkedIn</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Facebook</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Youtube</a>
                        </li>
                        <li className="Socials">
                          <a href="#">Instagram</a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] h-full flex flex-col justify-center items-center">
                  <div className="w-full h-full flex flex-col justify-center items-center gap-[13.194vw]">
                    <div className="flex flex-col w-[8.889vw] h-[22.222vw] gap-[1.944vw]">
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          About
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Gallery
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Careers
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Process
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          FAQ
                        </a>
                      </li>
                      <li className="menuItems">
                        <a className="menuItems" href="#">
                          Contact Us
                        </a>
                      </li>
                    </div>
                    <div className="w-full h-[14.861vw] flex flex-col justify-center items-center ml-[7.083vw] gap-[5.556vw]">
                      <div className="w-full h-full flex flex-col justify-start gap-[2.778vw]">
                        <div className="">Newsletterasd</div>
                        <div className="flex justify-start items-center gap-[0.7vw]">
                          <TextField
                            id="input-with-sx"
                            label="Email"
                            variant="standard"
                            className="w-[16.8vw]"
                            InputProps={{ disableUnderline: true }}
                            sx={{
                              "& .MuiInputLabel-root": { color: "black" },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiInputBase-root": {
                                borderBottom: "1px solid black",
                                transition: "none",
                                height: "3.8vw",
                              },
                              "& .MuiInputBase-root:focus-within": {
                                borderBottom: "1px solid black",
                              },
                              "& .MuiInputBase-input": {
                                padding: 0,
                                height: "100%",
                              },
                            }}
                          />
                          <div className="">Subscribe</div>
                        </div>
                        <div className="flex justify-start item-start gap-[2.778vw]">
                          <li
                            style={{ marginRight: "initial" }}
                            className="p-[0]"
                          >
                            <a className="SoP">
                              <button href="#">Stay at Opatan</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Company Profile</button>
                            </a>
                          </li>
                          <li className="p-[0]">
                            <a className="Normal">
                              <button href="#">Catalogue</button>
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <ul>
              <li className="w-[8.33vw] h-[5vw]  object-contain p-0">
                <a href="#">
                  <img className="w-full h-100%" src={opatan} alt="" />
                </a>
              </li>
              <li>
                <a href="#">Land</a>
              </li>
              <li>
                <a href="#">Design & Planning</a>
              </li>
              <li>
                <a href="#">Legal Permits</a>
              </li>
              <li>
                <a href="#">Art Building & Interior</a>
              </li>
              <li>
                <a>|</a>
              </li>
              <li>
                <a href="#">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleDropdown}
                  >
                    <g id="mdi:menu">
                      <path
                        id="Vector"
                        d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z"
                        fill="#13361C"
                      />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        );
    }
  };
  return <>{renderNavbar()}</>;
}
