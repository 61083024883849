import { useState, useRef, useEffect } from "react";
import arrow from "../assets/arrow.png";
import search from "../assets/search.png";
import art_building from "../assets/art_building.jpeg";
import bathub from "../assets/bathub.jpeg";
import arrowDown from "../assets/arrowDown.png";
import overview from "../assets/overview.jpeg";
import detail from "../assets/detail.jpeg";
import { Box, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import realEstate1 from '../assets/realEstate1.jpg'
import realEstate2 from '../assets/realEstate2.jpg'
import realEstate3 from '../assets/realEstate3.jpg'
import getInTouch from '../assets/getInTouch.jpeg'
import arrowTopRightWhite from '../assets/arrowTopRightWhite.png'
import arrowLeft from '../assets/arrowLeft.png'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { apiHandler, apiImageFilesHandler, apiImageHandler, apiImageHeaderHandler } from "../utilities/apiHandler";
import { useDebounce } from 'use-debounce';
import { useNavigate, useParams } from "react-router-dom";
import { i } from "motion/react-client";

export function ArtOverview({ data }) {

  const [index, setIndex] = useState(1);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full relative z-20 bg-white">
      <div className="flex justify-center items-center w-full h-full gap-[8vw] m-[5.5vw]">
        <div className="w-[46.6vw] h-[43.5vw] bg-red-200">
          <img className="w-full h-full object-cover" src={data.overview_image}></img>
        </div>
        <div className="flex flex-col justify-start items-start w-[35%] h-full gap-[1.7vw]">
          <div className="text-md">{data.subtitle}</div>
          <div className="text-sm">{data.detail}</div>
        </div>
      </div>
    </div>
  );
}

export function ArtDetail({ data }) {
  const [index, setIndex] = useState(1);
  const swiperRef = useRef(null);
  const { type } = useParams()

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-[1.3vw] bg-white relative z-20">
      <div className="w-full flex gap-[2.8vw] items-center justify-end pr-[5.5vw]">
        {index > 1 && (
          <button
            className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
            id="swiper-prev"
            onClick={() => {
              const swiper = swiperRef.current;
              if (swiper && index > 0) {
                swiper.slidePrev(); // Pindah ke slide sebelumnya
              }
            }}
          >
            <img src={arrowLeft} className="w-[2vw]" alt="Previous" />
          </button>
        )}
        <button
          className="w-[7.2vw] h-[2.8vw] flex justify-center items-center border-[1px] border-black"
          id="swiper-next"
          onClick={() => {
            const swiper = swiperRef.current;
            if (swiper && index < data.length) {
              swiper.slideNext(); // Navigasi ke slide berikutnya
            }
          }}
          disabled={index >= data.length} // Nonaktifkan tombol jika sudah mencapai batas
        >
          <img src={arrow} className="w-[2vw]" alt="Next" />
        </button>
        <div className="text-lg">
          <span id="current-slide">{index}</span>/{data.length}
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={false}
        modules={[Navigation]}
        spaceBetween={0}
        slidesPerView={3}
        slidesPerGroup={1}
        onSlideChange={(swiper) => {
          setIndex(Math.min(swiper.activeIndex + 1, data.length));
        }}
        className="w-full h-[44vw] flex"
      >
        {data.map((item, idx) => (
          <SwiperSlide
            key={idx}
            // hover:!h - [44vw]
            className={`basis-[33.3%] w-[33.3vw] !h-[42.4vw] flex justify-center items-center !transition-all !duration-300 !ease-in-out hover:basis-[38%] border-black border-y-[1px] border-l-[1px]  ${idx === data.length - 1 ? "border-black border-r-[1px]" : ""}`}
          >
            <div className="w-full h-[43.2vw] flex flex-col justify-start "
            >
              <div className="w-full h-[23.7vw]">
                <img
                  src={item.image}
                  alt={`Art ${idx + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-full px-[1.6vw] relative">
                <div className="w-full flex flex-col justify-center items-start mt-[2.7vw] relative">
                  <div className="text-md">{item.title}</div>
                </div>
                <div className="w-[30vw] mt-[1.6vw] text-[1vw] relative">{item.detail}</div>
                <div className="bottom-0 mt-[1vw] p-[0.5vw] bg-darkGreen w-fit text-white cursor-pointer" onClick={() => (window.location.href = `/card/${type}/${item.id}`)}>Learn More</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Tambahkan slide bayangan */}
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
        <SwiperSlide className="basis-[30%] w-[33.3vw]"></SwiperSlide>
      </Swiper>
    </div>
  );
}

export function ArtVirtualTour({ dataTemp }) {
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 1000); // Debounce selama 1 detik

  const [data, setData] = useState([])
  const { type } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        let searchData = ""

        if (type == undefined || type == "real_estate") {
          if (value == "") {
            searchData = await apiHandler("real_estate_sliders?limit=4");
          } else {
            searchData = await apiHandler("real_estate_sliders?filter[title][_icontains]=" + value);
          }
        } else {
          if (value == "") {
            searchData = await apiHandler("art_" + type + "_sliders?limit=4");
          } else {
            searchData = await apiHandler("art_" + type + "_sliders?filter[title][_icontains]=" + value);
          }
        }

        searchData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        })
        console.log(searchData)
        setData(searchData)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [value]); // Hanya dijalankan saat `value` berubah

  return (
    <div className="w-full h-fit flex flex-col justify-center items-center relative z-20 bg-white">
      <div className="w-full h-[10.5vw] flex items-center gap-[7.4vw] pl-[5.6vw] ">
        <Box
          sx={{ display: "flex", alignItems: "flex-end" }}
          className="w-[43.8vw] h-[5vw] border-b-[1px] border-black flex justify-start items-center gap-[1.4vw]"
        >
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="Search Art"
            variant="standard"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-[43.8vw]"
            InputProps={{ disableUnderline: true }}
            sx={{
              "& .MuiInputLabel-root": { color: "black" },
              "& .MuiInputLabel-root.Mui-focused": { color: "black" },
            }}
          />
        </Box>
        <div className="w-[7vw] h-[5vw] flex justify-start items-center gap-[1.6vw]">
          <div>Filter By</div>
          <div className="w-[0.9vw] h-[0.6vw] ">
            <img className="w-full h-full object-contain" src={arrowDown}></img>
          </div>
        </div>
      </div>
      <div className="grid grid-rows-2 grid-cols-2 w-full h-[94vw] gap-[2.8vw] px-[4.5vw] py-[2.8vw]">
        {data.map((data, index) => (
          <div
            key={index}
            className="w-full h-full bg-red-300 group text-white bg-no-repeat"
            style={{
              backgroundImage: `url(${data.image})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="flex flex-col w-full h-full pt-[1.8vw] pl-[3.1vw] pr-[3.2vw] pb-[3.6vw] justify-between group-hover:bg-black group-hover:bg-opacity-20 transition">
              <div className="hidden group-hover:block">{data.title}</div>

              <div className="hidden group-hover:flex justify-end items-center pr-[7.4vw]">
                <div className="w-[14vw] h-[3.5vw] flex justify-center items-center bg-darkGreen bg-opacity-30">
                  Virtual Tour
                </div>
              </div>

              <div className="hidden group-hover:flex w-full justify-between items-center">
                <div>Share</div>
                <div onClick={() => (window.location.href = `/detail/${type}/${data.id}`)} className="cursor-pointer">Read More</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function GetInTouchHome() {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiHandler("get_in_touch");
        data.image = await apiImageHandler(data.image);

        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full h-[34.9vw] text-white relative z-20">
      <div className="w-full h-full flex flex-col justify-center items-center py-[11.1vw] gap-[2.2vw] relative bg-gradient-to-b from-black/0 to-black/70">
        <img src={data.image} className="w-full h-full object-cover absolute z-[-10] "></img>
        <div className="text-[4.4vw]">{data.title}</div>
        <div className="flex flex-col justify-center items-center gap-[4.4vw] w-full h-full text-[1.1vw]">
          <div className="">
            {data.description}
          </div>
          <div className="w-[8.2vw] h-[3vw] flex justify-center items-center border-[1px] border-white">
            {data.title}
          </div>
        </div>
      </div>
    </div>
  );
}

export function GetInTouch() {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiHandler("get_in_touch");
        data.image = await apiImageHandler(data.image);

        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full h-[55.9vw] text-white relative z-20 flex justify-center px-[5.5vw] overflow-hidden">
      {/* <div className="w-full h-[15.6vw] bg-white absolute z-20 flex justify-center">
        <p
          id="title_masking"
          className="absolute z-20 text-xxl font-extrabold bg-clip-text text-transparent "
          style={{ backgroundImage: `url(${data.image})`, backgroundSize: "104vw 68vw", backgroundPosition: "-11.4vw -3.4vw" }}
        >
          {data.title}
        </p>
      </div> */}
      <div className="z-30 absolute bottom-[10vw] text-xxl font-extrabold">{data.title}</div>
      <div className="w-full h-full relative ">
        {/* Gambar */}
        <img className="w-full h-full object-cover" src={data.image} alt="Image" />

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/80  to-transparent"></div>
        <div className="absolute z-20  text-white left-[4.1vw] bottom-[5.7vw] flex justify-center items-center gap-[1vw] text-md">
          <div>Contact Us</div>
          <img src={arrowTopRightWhite} className="w-[1vw] h-[1vw]"></img>
        </div>
      </div>

    </div>
  );

}

export default function ArtBuildingHeader({ data, files }) {
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  return (
    <div className="w-full h-[71.6vw] flex flex-col justify-center items-end relative z-20 bg-white">
      <svg className="absolute w-[56.7vw] h-[14.1vw] text-[11.1vw] right-[30.5vw] top-[30vw]  pointer-events-none">
        <defs>
          <mask id="outsideMask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <rect
              x="31vw"
              y="0"
              width="12.7vw"
              height="100%"
              fill="black"
              style={{
                transition: "opacity 0.2s ease-in-out",
                opacity: hovered ? 1 : 0,
              }}
            />
            <rect
              x="45vw"
              y="0"
              width="12.7vw"
              height="100%"
              fill="black"
              style={{
                transition: "opacity 0.2s ease-in-out",
                opacity: hovered2 ? 1 : 0,
              }}
            />
          </mask>
        </defs>
        <text
          x="0"
          y="70%"
          fontSize="10.4vw"
          mask="url(#outsideMask)"
          fill="#13361C"
        >
          {data.title}
        </text>
      </svg>
      <div className="flex items-center w-[56.7vw] h-full ">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="w-fit h-full px-[0.6vw] flex items-end pb-[9.6vw] "
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={files[0]}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-start pt-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={files[1]}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-end pb-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={files[2]}
            ></img>
          </div>
        </div>

        <div
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          className="w-fit h-full px-[0.6vw] flex items-start pt-[9.6vw] border-lightGray border-l-[1px]"
        >
          <div className="w-[12.7vw] h-[44vw] flex ">
            <img
              className="w-full h-full object-cover"
              src={files[3]}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}


export function ParallaxRealEstate() {

  const [data, setData] = useState([]);
  const [files, setFiles] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiHandler("art_interior_detail_header");
        const files = await apiImageHeaderHandler("real_estate", data.header_images)
        console.log(data)
        setFiles(files)
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className='w-[100vw] h-[66vw] relative z-20 bg-white' >
      <Parallax pages={1.1} style={{ top: '0', left: '0' }} className="scrollbar-hide !relative">
        <ParallaxLayer offset={0} speed={1.2} >
          <div className='w-[24vw] h-[25.4vw]  absolute top-[2.7vw] left-[26.2vw]'>
            <img src={files[0]} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1}>
          <div className='w-[22vw] h-[32.7vw]  absolute top-[12.2vw] left-[73.6vw]'>
            <img src={files[1]} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={5}>
          <div className='w-[59vw] h-[14.1vw] absolute top-[50.6vw] left-[33.6vw] text-[11.1vw]'>
            Real Estate
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1}>
          <div className='w-[32.7vw] h-[39vw] absolute top-[38.1vw] left-[5.5vw] '>
            <img src={files[2]} className='w-full h-full object-cover'></img>
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}
