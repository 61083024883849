import { useEffect, useState } from "react";
import {
  ArtDetail,
  ArtOverview,
  ArtVirtualTour,
  GetInTouch,
  ParallaxRealEstate,
} from "../../components/ArtComponents";
import Footer from "../../components/FooterComponents";
import Navbar from "../../components/NavbarComponents";
import { apiHandler, apiImageHandler } from "../../utilities/apiHandler";
import { Preloader } from "../Home";

export default function RealEstatePage() {
  const [realEstateData, setRealEstateData] = useState([]);
  const [realEstateListData, setRealEstateListData] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const realEstateData = await apiHandler("real_estate_sliders");
        realEstateData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        const headerData = await apiHandler("real_estate_detail_header");
        headerData.overview_image = await apiImageHandler(
          headerData.overview_image
        );

        console.log(headerData);

        const realEstateListData = await apiHandler("real_estate_lists");
        realEstateListData.map(async (item) => {
          item.image = await apiImageHandler(item.image);
          return item;
        });

        setHeaderData(headerData);
        setRealEstateListData(realEstateListData);
        setRealEstateData(realEstateData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[100vw] h-full flex flex-col items-center justify-center ">
      <Preloader /> 
      <Navbar type="default" />
      <div className="overflow-y-hidden w-full h-full">
        <ParallaxRealEstate />
      </div>
      <ArtOverview data={headerData} />
      <ArtDetail data={realEstateData} />
      <ArtVirtualTour data={realEstateListData} />
      <GetInTouch />
      <Footer />
    </div>
  );
}
