import { useParams } from "react-router-dom";
import { ArtDetail, ArtOverview, ArtVirtualTour, GetInTouch } from "../../components/ArtComponents";
import Footer from "../../components/FooterComponents";

export default function ArtPage(){
    const { type } = useParams()
    console.log(type)
    return (
        <div className="flex flex-col justify-center items-center overflow-hidden max-w-[100vw] ">
            <ArtOverview/>
            <ArtDetail/>
            <ArtVirtualTour/>
            <GetInTouch/>
            <Footer/>
        </div>
    )
}
