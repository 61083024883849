export default function Home(){
    return (
         <>
        <h1 className="font-bold underline">Home</h1>
        <a href="https://opatan.id/contact">Contact</a>
        <a href="https://opatan.id/real-estate">real-estate</a>
        <a href="https://opatan.id/detail/building">Building detail</a>
        <a href="https://opatan.id/about-us">About us</a>
        <a href="https://opatan.id/faq">faq</a>
        <a href="https://opatan.id/art/art">Art type</a>
         </>
    )
}
